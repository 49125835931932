import { AiOutlineUser } from "react-icons/ai";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useAuth } from "@Contexts/AuthContext";
import { Button, Navbar, Container, Nav } from "react-bootstrap";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@Config/firebase";
import BioRetailPNG from "@Assets/images/BioRetail-PURPLE B.png";
import { useData } from "@Contexts/DataContext";

export default function Navigation() {
  const [role, setRole] = useState("");
  const [userData, setUserData] = useState<any>({});
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { organizations } = useData();
  const location = useLocation();

  const options: any = {
    broker: [Publish],
    admin: [Dashboard, Databases, TopRetail, Templates, Polygons],
    manager: [
      Dashboard,
      Organizations,
      Search,
      Explore,
      Project,
      Model,
      DataMarket,
    ],
  };
  useEffect(() => {
    if (user) {
      (async () => {
        const docRef = await getDoc(doc(db, `users/${user.uid}`));
        setUserData(docRef?.data());
        setRole(docRef?.data()?.role);
      })();
    }
  }, [user]);

  const urls = [
    "/dashboard",
    "/profile",
    "/admin/databases",
    "/admin/top_retail",
    "/admin/templates",
    "/admin/polygons",
    "/manager/search",
    "/manager/organizations",
    "/manager/explore",
    "/manager/projects",
    "/manager/models",
    "/broker/venue-add",
    "/manager/market",
  ];
  const handleLogout = () => {
    window.location.reload();
  };

  if (user && urls.some((url) => location.pathname.includes(url))) {
    return (
      <>
        <div
          className="bg-csecondary flex flex-col justify-between sidebar"
          style={{ padding: 12 }}
        >
          <div>
            <h4
              className="text-cprimary"
              style={{
                fontWeight: "bold",
                textAlign: "left",
                margin: "10px 0px 15px 0px",
              }}
            >
              Locales Fantasticos
            </h4>
            <hr style={{ border: "1px rgb(255 29 127) solid" }} />
            <ul className="nav flex-column" style={{ gap: 4 }}>
              {options[role]?.map?.((Opt: any, key: number) => (
                <Opt navigate={navigate} key={key} />
              ))}
            </ul>
          </div>
          <div>
            <hr style={{ border: "1px rgb(255 29 127) solid" }} />
            <Link className="text-black no-underline" to="/profile">
              <div className="bg-white rounded-lg flex justify-between px-2 py-2 mb-2">
                <div>
                  {userData && (
                    <div>
                      {userData.firstName ? (
                        <>
                          {userData.firstName} {userData?.lastName}
                        </>
                      ) : (
                        user?.email?.split?.("@")?.[0]
                      )}
                    </div>
                  )}
                  <div className="text-xs -mt-[5px] opacity-70">
                    {user?.data?.default_organization &&
                    organizations.filter(
                      (org) => org.id === user?.data?.default_organization
                    ).length
                      ? organizations.filter(
                          (org) => org.id === user?.data?.default_organization
                        )[0].name
                      : String(userData.role).toUpperCase()}
                  </div>
                </div>
                <div>
                  {userData.profileImage ? (
                    <img
                      src={userData.profileImage}
                      className="w-[35px] rounded-full"
                    />
                  ) : (
                    <div className="bg-csecondary text-cprimary rounded-full w-[35px] h-[35px] grid place-items-center">
                      <AiOutlineUser className="text-xl" />
                    </div>
                  )}
                </div>
              </div>
            </Link>
            <Button
              onClick={() => {
                logout().then(handleLogout);
              }}
              id="theme-button"
              className="w-full"
            >
              Cerrar sesión
            </Button>
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <Navbar
          collapseOnSelect
          expand="lg"
          style={{ height: 70 }}
          className="bg-csecondary"
        >
          <Container>
            <Navbar.Brand
              className="flex items-center"
              onClick={() => navigate("/")}
            >
              <img src={BioRetailPNG} alt="" className="h-[36px]" />
              Locales Fantasticos
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link
                  id="theme-text"
                  onClick={() => {
                    if (user) navigate("/broker/venue-add");
                    else navigate("/login");
                  }}
                >
                  Publica tu Local
                </Nav.Link>
                <Nav.Link id="theme-text" href="#pricing">
                  FAQs
                </Nav.Link>
                <Nav.Link id="theme-text" href="#pricing">
                  About
                </Nav.Link>
              </Nav>
              <Nav>
                {user?.email ? (
                  <>
                    <Link className="text-black no-underline" to="/profile">
                      <div className="gap-3 bg-white rounded-lg flex justify-between px-2 py-2">
                        <div>
                          <div>
                            {userData.firstName ? (
                              <>
                                {userData.firstName} {userData?.lastName}
                              </>
                            ) : (
                              user.email.split("@")[0]
                            )}
                          </div>
                          <div className="text-xs -mt-[5px] opacity-70">
                            {String(userData.role).toUpperCase()}
                          </div>
                        </div>
                        <div>
                          {userData.profileImage ? (
                            <img
                              src={userData.profileImage}
                              className="w-[35px] rounded-full"
                            />
                          ) : (
                            <div className="bg-csecondary text-cprimary p-1 rounded-full">
                              <AiOutlineUser />
                            </div>
                          )}
                        </div>
                      </div>
                    </Link>
                  </>
                ) : (
                  <>
                    {location.pathname !== "/login" && (
                      <button
                        onClick={() => navigate("/login")}
                        className="btn me-2"
                        id="theme-button-outline"
                      >
                        Login
                      </button>
                    )}
                    {location.pathname !== "/signup" && (
                      <button
                        onClick={() => navigate("/signup")}
                        className="btn"
                        id="theme-button"
                      >
                        Sign Up
                      </button>
                    )}
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
}

const Dashboard = ({ navigate }: any) => (
  <li className="nav-item opcion-menu" id="dashboard">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/dashboard")}
    >
      Dashboard
    </Button>
  </li>
);

const Organizations = ({ navigate }: any) => (
  <li className="nav-item opcion-menu" id="dashboard">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/manager/organizations")}
    >
      Organizations
    </Button>
  </li>
);

const Publish = ({ navigate }: any) => (
  <li className="opcion-menu" id="publish">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/broker/venue-add")}
    >
      Public Locales
    </Button>
  </li>
);

const Search = ({ navigate }: any) => (
  <li className="opcion-menu" id="search">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/manager/search")}
    >
      Busca Local
    </Button>
  </li>
);

const Explore = ({ navigate }: any) => (
  <li className="opcion-menu" id="explore">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/manager/explore")}
    >
      Explora
    </Button>
  </li>
);

const Databases = ({ navigate }: any) => (
  <li className="opcion-menu" id="databases">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/admin/databases")}
    >
      Databases
    </Button>
  </li>
);
const TopRetail = ({ navigate }: any) => (
  <li className="opcion-menu" id="topretail">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/admin/top_retail")}
    >
      Top Retail
    </Button>
  </li>
);
const Templates = ({ navigate }: any) => (
  <li className="opcion-menu" id="templates">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/admin/templates")}
    >
      Templates
    </Button>
  </li>
);
const Polygons = ({ navigate }: any) => (
  <li className="opcion-menu" id="isochrones">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/admin/polygons")}
    >
      Polygons
    </Button>
  </li>
);

const Model = ({ navigate }: any) => (
  <li className="opcion-menu" id="model">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/manager/models")}
    >
      Crear modelo
    </Button>
  </li>
);

const Project = ({ navigate }: any) => (
  <li className="nav-item opcion-menu" id="project">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/manager/projects")}
    >
      Proyectos
    </Button>
  </li>
);

const DataMarket = ({ navigate }: any) => (
  <li className="nav-item opcion-menu" id="datastore">
    <Button
      id="theme-button-outline"
      style={{ textAlign: "left" }}
      className="nav-link w-100"
      onClick={() => navigate("/manager/market")}
    >
      Data Market
    </Button>
  </li>
);
