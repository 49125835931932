import React, { useState, useEffect } from "react";
import { Button, Modal, FloatingLabel, Form } from "react-bootstrap";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import { db } from "@Config/firebase";
import { collection, addDoc } from "firebase/firestore";
import CustomPolygon from "./CustomPolygon";
import { BsBuildings } from "react-icons/bs";
import { AiFillShop } from "react-icons/ai";
import { RxCorners, RxCornerTopRight } from "react-icons/rx";
import { FaTimes, FaPlus, FaParking, FaFile } from "react-icons/fa";
import { Scrollbar } from "react-scrollbars-custom";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Image = ({ ...rest }: any) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <div style={{ height: rest.height }} className="relative">
      {!loaded && <></>}
      <img
        style={{ opacity: loaded ? 1 : 0 }}
        onLoad={() => setLoaded(true)}
        {...rest}
      />
    </div>
  );
};

const saveProject = async (
  projectName: string,
  pipeline: string,
  userId: string,
  templateName: string,
  localeIds: string[],
  organizationId: string,
  isochrone: any[]
) => {
  try {
    await addDoc(collection(db, `organizations/${organizationId}/projects`), {
      name: projectName,
      pipeline: pipeline,
      userId: userId,
      templateName: templateName,
      localeIds: localeIds,
      isochrone: isochrone,
      createdAt: new Date(),
    });
  } catch (error) {
    console.error("Error saving project:", error);
  }
};

const Project: React.FC<{ isochrone: any[]; delayedToggleState: any }> = ({
  isochrone,
  delayedToggleState,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [projectName, setProjectName] = useState<string>("");
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [pipelineContent, setPipelineContent] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [errors, setErrors] = useState<{
    projectName?: string;
    template?: string;
  }>({});
  const [selectedLocales, setSelectedLocales] = useState<Set<string>>(
    new Set()
  );
  const [loading, setLoading] = useState(false); // Add loading state
  const { user } = useAuth();
  const data = useData();
  const { templates, locales } = data;

  const userDefaultOrgId = user?.data?.default_organization;
  const organization = data.organizations?.find(
    (org) => org.id === userDefaultOrgId
  );

  const isLocaleInIsochrone = (locale: any) => {
    const polygon = new CustomPolygon(
      isochrone.map((cords: any) => [cords[0], cords[1]])
    );
    return polygon.contains([
      locale.location.longitude,
      locale.location.latitude,
    ]);
  };

  const localesInIsochrone = locales.filter((locale: any) => {
    return (
      locale.visibility &&
      (locale.visibility.includes(user?.data?.default_organization) ||
        locale.visibility.length === 0) &&
      isLocaleInIsochrone(locale)
    );
  });

  useEffect(() => {
    if (modalVisible) {
      setProjectName("");
      setSelectedTemplateId("");
      setPipelineContent("");
      setTemplateName("");
      setErrors({});
      setSelectedLocales(
        new Set(localesInIsochrone.map((locale) => locale.id))
      );
    }
  }, [modalVisible]);

  useEffect(() => {
    const selectedTemplate = templates.find(
      (template) => template.id === selectedTemplateId
    );
    setPipelineContent(selectedTemplate?.pipeline || "");
    setTemplateName(selectedTemplate?.name || "");
  }, [selectedTemplateId, templates]);

  const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const templateId = e.target.value;
    setSelectedTemplateId(templateId);

    const selectedTemplate = templates.find(
      (template) => template.id === templateId
    );
    setTemplateName(selectedTemplate?.name || "");
  };

  const toggleLocaleSelection = (localeId: string) => {
    setSelectedLocales((prev) => {
      const updated = new Set(prev);
      if (updated.has(localeId)) {
        updated.delete(localeId);
      } else {
        updated.add(localeId);
      }
      return updated;
    });
  };

  const handleSaveProject = async () => {
    const newErrors: { projectName?: string; template?: string } = {};

    if (!projectName.trim()) {
      newErrors.projectName = "Project name is required.";
    }

    if (!selectedTemplateId) {
      newErrors.template = "Template selection is required.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (user && organization) {
      setLoading(true); // Set loading to true
      try {
        const processedIsochrone = isochrone.map((coord: any) => ({
          latitude: coord[1],
          longitude: coord[0],
        }));

        await saveProject(
          projectName,
          pipelineContent,
          user.uid,
          templateName,
          Array.from(selectedLocales),
          organization.id,
          processedIsochrone
        );
        setModalVisible(false);
      } catch (error) {
        console.error("Error saving project:", error);
      } finally {
        setLoading(false); // Set loading to false
      }
    } else {
      console.error("User is not authenticated or organization is not found");
    }
  };
  return (
    <>
      <Button
        id="theme-button"
        variant="primary"
        onClick={() => setModalVisible(true)}
        style={{
          borderRadius: "50%",
          width: "45px",
          height: "45px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          position: "fixed",
          bottom: "10px",
          left: delayedToggleState ? "410px" : "310px",
          zIndex: 1000,
          transition: "all 0.5s ease",

          flexDirection: modalVisible ? "column" : "row",

          padding: modalVisible ? "10px" : "initial",

          overflowY: modalVisible ? "auto" : "hidden",
          boxShadow: modalVisible ? "0 4px 8px rgba(0,0,0,0.2)" : "none",
          border: modalVisible ? "1px solid #ddd" : "none",
        }}
        disabled={isochrone.length === 0}
      >
        <FaFile size={12} />
      </Button>
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col gap-3">
            <div className="mb-1">
              <FloatingLabel controlId="projectName" label="Project Name">
                <Form.Control
                  type="text"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  className="form-control-sm"
                />
              </FloatingLabel>
              {errors.projectName && (
                <div className="text-red-500 text-xs mt-1">
                  {errors.projectName}
                </div>
              )}
            </div>
            <div>
              <FloatingLabel
                controlId="templateSelect"
                label="Select Template"
                className="mt-[-13px] mb-[2px]"
              >
                <Form.Select
                  value={selectedTemplateId}
                  onChange={handleTemplateChange}
                  className="form-select-sm"
                  aria-label="Select template"
                >
                  <option value="" disabled hidden></option>
                  {templates.map((template) => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>

              {errors.template && (
                <div className="text-red-500 text-xs mt-1">
                  {errors.template}
                </div>
              )}
            </div>
            {localesInIsochrone.length > 0 ? (
              <Scrollbar style={{ width: "100%", height: 290 }}>
                <div className="flex flex-row gap-2">
                  {localesInIsochrone.map((locale: any) => (
                    <div
                      key={locale.id}
                      className={`relative flex-none w-60 rounded-xl border p-2 bg-white shadow-sm transition-opacity duration-300 ${
                        selectedLocales.has(locale.id)
                          ? "opacity-100"
                          : "opacity-50"
                      }`}
                    >
                      <Image
                        src={locale.images[0]}
                        height={80}
                        className="w-full h-auto rounded min-w-[100%] drop-shadow-sm"
                        alt="Locale Image"
                      />
                      <div className="p-1 flex flex-col gap-1">
                        <h3
                          className="text-xs font-bold mt-5 text-left"
                          style={{ minHeight: "2.5em" }}
                        >
                          {locale.location.address}
                        </h3>

                        <div
                          className="flex gap-1 flex-wrap opacity-70"
                          style={{ minHeight: "2.5em" }}
                        >
                          <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                            <RxCorners className="text-[10px]" />
                            <span className="text-[11px]">
                              {locale.sqMeter} m<sup>2</sup>
                            </span>
                          </h3>
                          <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                            <BsBuildings className="text-[10px]" />
                            <span className="text-[11px]">
                              {locale.floors} Floor(s)
                            </span>
                          </h3>

                          {locale.haveParking && (
                            <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                              <FaParking className="text-[10px]" />
                              <span className="text-[11px]">Parking</span>
                            </h3>
                          )}

                          {locale.isCorner && (
                            <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                              <RxCornerTopRight className="text-[10px]" />
                              <span className="text-[11px]">Corner</span>
                            </h3>
                          )}

                          {locale.commercial && (
                            <h3 className="flex gap-1 items-center text-xs m-0 p-0 leading-[1.2]">
                              <AiFillShop className="text-[10px]" />
                              <span className="text-[11px]">Commercial</span>
                            </h3>
                          )}
                        </div>
                        <div className="flex justify-between items-end h-full">
                          <h1 className="text-[16px] font-bold">
                            {USDollar.format(locale.price)}
                          </h1>
                          <button
                            className="absolute bottom-2 right-2 text-red-500 rounded-full w-6 h-6 flex items-center justify-center shadow"
                            onClick={() => toggleLocaleSelection(locale.id)}
                            id="theme-button"
                          >
                            {selectedLocales.has(locale.id) ? (
                              <FaTimes />
                            ) : (
                              <FaPlus />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Scrollbar>
            ) : (
              <div className="flex items-center justify-center">
                <p className="text-center text-gray-500">
                  No locales available in this Isochrone
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-between">
          <Button
            variant="secondary"
            onClick={() => setModalVisible(false)}
            className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            id="theme-button"
            onClick={handleSaveProject}
            disabled={loading} // Disable button while loading
          >
            {loading ? "Saving..." : "Create"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Project;
