import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { MdWarning } from "react-icons/md";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { GoChevronUp, GoChevronRight } from "react-icons/go";
import { Button, Badge } from "react-bootstrap";
import { useData } from "@Contexts/DataContext";

const mapContainerWidth = "100%";

const convertToPipelineStages = (
  pipeline: HierarchicalPipeline
): PipelineStage[] => {
  return Object.entries(pipeline).map(([stageName, tasks]) => {
    const stageTasks: PipelineTask[] = Object.entries(tasks).map(
      ([taskName, subtasksOrSelected]) => {
        let members: string[] = []; // Initialize as an array of strings (user IDs)

        if (typeof subtasksOrSelected === "boolean") {
          // If no subtasks, return a task with no members
          return {
            name: taskName || "",
            selected: subtasksOrSelected,
            subtasks: [],
            members, // Ensure this is included and is an array
          };
        } else {
          const taskSelected = Object.values(subtasksOrSelected).every(
            (value) => value === true
          );

          if ("members" in subtasksOrSelected) {
            if (Array.isArray(subtasksOrSelected.members)) {
              members = subtasksOrSelected.members as string[];
            }
          }

          const subtasks: PipelineSubtask[] = Object.entries(subtasksOrSelected)

            .filter(([subtaskName]) => subtaskName !== "members")
            .map(([subtaskName, selected]) => ({
              name: subtaskName || "",
              selected: selected as boolean,
            }));

          return {
            name: taskName || "",
            selected: taskSelected,
            subtasks,
            members, // Now always an array of user IDs
          };
        }
      }
    );

    let stageSelected: boolean;
    if (stageTasks.length === 0) {
      stageSelected = pipeline[stageName] as boolean;
    } else {
      stageSelected = stageTasks.every((task) => task.selected);
    }

    return {
      name: stageName || "",
      selected: stageSelected,
      tasks: stageTasks,
    };
  });
};

const transformToHierarchical = (
  stages: PipelineStage[]
): HierarchicalPipeline => {
  return stages.reduce((acc, stage) => {
    if (stage.tasks.length === 0) {
      // If no tasks, assign boolean directly
      acc[stage.name] = stage.selected as boolean;
    } else {
      const taskAcc: Record<string, boolean | Record<string, boolean>> = {};
      stage.tasks.forEach((task) => {
        if (task.subtasks.length === 0) {
          // If no subtasks, assign boolean directly
          taskAcc[task.name] = task.selected as boolean;
        } else {
          const subtaskAcc: Record<string, boolean> = {};
          task.subtasks.forEach((subtask) => {
            // Only include subtasks that have a boolean value
            if (subtask.selected !== undefined) {
              subtaskAcc[subtask.name] = subtask.selected as boolean;
            }
          });
          // Assign task with subtasks if there are any subtasks
          taskAcc[task.name] =
            Object.keys(subtaskAcc).length > 0
              ? subtaskAcc
              : (task.selected as boolean);
        }
      });
      acc[stage.name] = taskAcc;
    }
    return acc;
  }, {} as HierarchicalPipeline);
};

const getExpiredTasks = (pipeline: HierarchicalPipeline): Set<string> => {
  const today = new Date();
  const expiredTasks = new Set<string>();

  const extractEndDates = (obj: any) => {
    if (obj && typeof obj === "object" && !Array.isArray(obj)) {
      Object.entries(obj).forEach(([key, value]) => {
        if (typeof value === "boolean") return; // Skip boolean values

        if (typeof value === "object" && value !== null) {
          // Use the key as the task name
          const taskName = key;

          // Check if the object has an endDate field
          const { endDate } = value as { endDate?: string };

          if (endDate) {
            const endDateObj = new Date(endDate);
            if (endDateObj < today) {
              expiredTasks.add(taskName); // Use task name as identifier
            }
          }

          // Recursively process nested objects
          extractEndDates(value);
        }
      });
    }
  };

  extractEndDates(pipeline);
  return expiredTasks;
};

const PipelineModal: React.FC<PipelineModalProps> = ({
  onSave,
  pipeline,
  onPipelineChange,
  setIsTaskClicked,
  setSelectedTask,
  alertMessage,
  setAlertMessage,
  taskDataSaved,
  setTaskDataSaved,
}) => {
  const [stages, setStages] = useState<PipelineStage[]>(
    convertToPipelineStages(pipeline)
  );
  const [prevPipeline, setPrevPipeline] = useState(pipeline);
  const { users } = useData();
  const [isSaving, setIsSaving] = useState(false);
  const [expandedStages, setExpandedStages] = useState<number[]>([]);
  const [expandedTasks, setExpandedTasks] = useState<{
    [key: number]: number[];
  }>({});

  const toggleStageExpand = (index: number) => {
    setExpandedStages((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const toggleTaskExpand = (stageIndex: number, taskIndex: number) => {
    setExpandedTasks((prev) => ({
      ...prev,
      [stageIndex]: prev[stageIndex]?.includes(taskIndex)
        ? prev[stageIndex].filter((i) => i !== taskIndex)
        : [...(prev[stageIndex] || []), taskIndex],
    }));
  };

  const handleStageNameChange = (index: number, name: string) => {
    const newStages = [...stages];
    newStages[index].name = name;
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleTaskNameChange = (
    stageIndex: number,
    taskIndex: number,
    name: string
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].name = name;
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleSubtaskNameChange = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number,
    name: string
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks[subtaskIndex].name = name;
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const addStage = () => {
    const newStage = { name: "", selected: false, tasks: [] };
    const newStages = [...stages, newStage];
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const deleteStage = (index: number) => {
    const newStages = stages.filter((_, i) => i !== index);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const addTask = (stageIndex: number) => {
    const newTask: PipelineTask = {
      name: "",
      selected: false,
      subtasks: [],
    };

    const newStages = [...stages];
    newStages[stageIndex].tasks.push(newTask);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const deleteTask = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks = newStages[stageIndex].tasks.filter(
      (_, i) => i !== taskIndex
    );
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const addSubtask = (stageIndex: number, taskIndex: number) => {
    const newSubtask = { name: "", selected: false };
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks.push(newSubtask);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const deleteSubtask = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number
  ) => {
    const newStages = [...stages];
    newStages[stageIndex].tasks[taskIndex].subtasks = newStages[
      stageIndex
    ].tasks[taskIndex].subtasks.filter((_, i) => i !== subtaskIndex);
    setStages(newStages);
    onPipelineChange({ stages: newStages });
  };

  const handleStageSelectionChange = (stageIndex: number) => {
    const newStages = [...stages];
    const isSelected = !newStages[stageIndex].selected;

    if (newStages[stageIndex].tasks.length > 0) {
      newStages[stageIndex].tasks = newStages[stageIndex].tasks.map((task) => {
        return {
          ...task,
          selected: isSelected,
          subtasks: task.subtasks.map((subtask) => {
            return {
              ...subtask,
              selected:
                typeof subtask.selected === "boolean"
                  ? isSelected
                  : subtask.selected,
            };
          }),
        };
      });
    } else {
      newStages[stageIndex].selected = isSelected;
    }

    setStages(newStages);
    onPipelineChange({ stages: newStages });
    saveJson();
  };

  const handleTaskSelectionChange = (stageIndex: number, taskIndex: number) => {
    const newStages = [...stages];
    const task = newStages[stageIndex].tasks[taskIndex];
    task.selected = !task.selected; // Toggle the selected property

    if (task.subtasks.length === 0) {
      // If the task has no subtasks, update the stage selection
      const allTasksSelected = newStages[stageIndex].tasks.every(
        (task) => task.selected
      );
      newStages[stageIndex].selected = allTasksSelected;
    } else {
      // If the task has subtasks, update the subtasks and stage selection
      const newSubtasks = task.subtasks.map((subtask) => ({
        ...subtask,
        selected: task.selected,
      }));
      task.subtasks = newSubtasks;

      const allTasksSelected = newStages[stageIndex].tasks.every(
        (task) => task.selected
      );
      newStages[stageIndex].selected = allTasksSelected;
    }

    setStages(newStages); // Update state
    onPipelineChange({ stages: newStages }); // Notify change
    setIsTaskClicked(true); // Update clicked state
    saveJson();
  };

  const saveJson = async () => {
    setIsSaving(true);
    const pipelineObject = transformToHierarchical(stages);
    try {
      await onSave(pipelineObject);
    } catch (error) {
      console.error("Failed to save pipeline:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSubtaskSelectionChange = (
    stageIndex: number,
    taskIndex: number,
    subtaskIndex: number
  ) => {
    const newStages = [...stages];
    const task = newStages[stageIndex].tasks[taskIndex];
    const subtask = task.subtasks[subtaskIndex];
    subtask.selected = !subtask.selected;

    if (task.subtasks.length === 0) {
      task.selected =
        typeof task.selected === "boolean" ? task.selected : false;
    } else {
      const booleanSubtasks = task.subtasks.filter(
        (subtask) => typeof subtask.selected === "boolean"
      );
      task.selected =
        booleanSubtasks.length > 0
          ? booleanSubtasks.every((subtask) => subtask.selected)
          : false;
    }

    const allTasksSelected = newStages[stageIndex].tasks.every(
      (task) => task.selected
    );
    newStages[stageIndex].selected = allTasksSelected;
    setStages(newStages);
    onPipelineChange({ stages: newStages });
    saveJson();
  };

  useEffect(() => {
    const initializeTaskSelection = () => {
      const newStages = stages.map((stage) => {
        if (stage.tasks.length === 0) {
          // Stage has no tasks, so allow stage selection to be toggled manually
          return {
            ...stage,
            selected:
              typeof stage.selected === "boolean" ? stage.selected : false,
          };
        } else {
          const updatedTasks = stage.tasks.map((task) => {
            if (task.subtasks.length === 0) {
              return {
                ...task,
                selected:
                  typeof task.selected === "boolean" ? task.selected : false,
              };
            } else {
              const booleanSubtasks = task.subtasks.filter(
                (subtask) => typeof subtask.selected === "boolean"
              );
              return {
                ...task,
                selected: booleanSubtasks.every((subtask) => subtask.selected),
              };
            }
          });

          const allTasksSelected = updatedTasks.every((task) => task.selected);
          return {
            ...stage,
            tasks: updatedTasks,
            selected: allTasksSelected,
          };
        }
      });

      setStages(newStages); // Update state with the new deep copy
    };

    // Only call this when pipeline changes, not stages
    if (!taskDataSaved) {
      initializeTaskSelection();
    }
  }, [taskDataSaved, pipeline]); // Remove stages from the dependency array

  // Pipeline data change handler
  useEffect(() => {
    if (taskDataSaved) {
      if (prevPipeline !== pipeline) {
        const updatedStages = stages.map((stage) => {
          const updatedTasks = stage.tasks.map((task) => {
            const booleanSubtasks = task.subtasks.filter(
              (subtask) => typeof subtask.selected === "boolean"
            );
            return {
              ...task,
              selected: booleanSubtasks.every((subtask) => subtask.selected),
            };
          });

          const allTasksSelected = updatedTasks.every((task) => task.selected);
          return {
            ...stage,
            tasks: updatedTasks,
            selected: allTasksSelected,
          };
        });

        setStages(updatedStages);
        setPrevPipeline(pipeline); // Update previous pipeline
        setTaskDataSaved(false); // Reset the flag after processing
      }
    }
  }, [taskDataSaved, pipeline, prevPipeline, stages]); // Add prevPipeline to dependencies

  useEffect(() => {
    if (taskDataSaved) {
      // Check if pipeline has changed to avoid unnecessary updates
      if (prevPipeline !== pipeline) {
        setStages(convertToPipelineStages(pipeline));
        setPrevPipeline(pipeline); // Update previous pipeline
        setTaskDataSaved(false); // Reset the flag after processing
      }
    }
  }, [taskDataSaved, pipeline]);

  return (
    <>
      <div className="space-y-4" style={{ width: mapContainerWidth }}>
        {alertMessage && (
          <Alert
            variant="danger"
            onClose={() => setAlertMessage(null)}
            dismissible
            className=" fixed top-0 left-1/2 transform -translate-x-1/2  w-2/3 text-black text-center p-2 border-b border-gray-200 shadow-lg"
          >
            {alertMessage}
          </Alert>
        )}
        {stages.map((stage, stageIndex) => (
          <div key={stageIndex} className="group mb-[16px]">
            <div className="flex items-center justify-between pl-4 mb-3 ">
              <Button
                onClick={() => toggleStageExpand(stageIndex)}
                className="d-flex align-items-center p-0 border-0 bg-transparent text-dark mr-5"
                variant="link"
              >
                {expandedStages.includes(stageIndex) ? (
                  <GoChevronUp size={20} />
                ) : (
                  <GoChevronRight size={20} />
                )}
              </Button>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  checked={stage.selected}
                  onChange={() => handleStageSelectionChange(stageIndex)}
                  className="sr-only"
                />
                <span
                  className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ${
                    stage.selected
                      ? "bg-green-500 border-green-500"
                      : "bg-transparent border-gray-300"
                  }`}
                >
                  <FaCheck
                    className={`text-${stage.selected ? "white" : "gray-400"}`}
                    size={10}
                  />
                </span>
              </label>

              <input
                type="text"
                value={stage.name}
                onChange={(e) =>
                  handleStageNameChange(stageIndex, e.target.value)
                }
                placeholder={`Stage ${stageIndex + 1}`}
                className="ml-6 p-1 border-none outline-none w-full"
              />

              {/* Show delete icon on hover */}
              <div className="group-hover:flex hidden">
                <Button
                  onClick={() => deleteStage(stageIndex)}
                  className="p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                  variant="link"
                >
                  <AiOutlineDelete
                    size={18}
                    className="text-red-500 hover:bg-red-200"
                  />
                </Button>
              </div>

              <Button
                onClick={() => addTask(stageIndex)}
                className="p-1 hover:bg-green-200 rounded-full text-green-500 hover:text-green-600"
                variant="link"
              >
                <AiOutlinePlus
                  size={16}
                  className="text-green-500 hover:bg-green-200"
                />
              </Button>
            </div>
            {expandedStages.includes(stageIndex) && (
              <div className="pl-4 ml-2 mt-3">
                {stage.tasks.map((task, taskIndex) => (
                  <div
                    key={taskIndex}
                    className="flex flex-col mb-[16px] group"
                  >
                    <div className="flex items-center ">
                      <div>
                        {getExpiredTasks(pipeline).has(task.name) && (
                          <MdWarning
                            size={18}
                            style={{
                              color: "red",
                              marginRight: "-18px",
                              marginLeft: "-10px",
                              marginBottom: "2px",
                            }}
                          />
                        )}
                      </div>

                      <Button
                        onClick={() => toggleTaskExpand(stageIndex, taskIndex)}
                        className="d-flex align-items-center p-0 border-0 bg-transparent text-dark mr-5"
                        variant="link"
                      >
                        {expandedTasks[stageIndex]?.includes(taskIndex) ? (
                          <GoChevronUp size={20} className="ml-3" />
                        ) : (
                          <GoChevronRight size={20} className="ml-3" />
                        )}
                      </Button>

                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          type="checkbox"
                          checked={task.selected}
                          onChange={() =>
                            handleTaskSelectionChange(stageIndex, taskIndex)
                          }
                          className="sr-only"
                        />
                        <span
                          className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ${
                            task.selected
                              ? "bg-green-500 border-green-500"
                              : "bg-transparent border-gray-300"
                          }`}
                        >
                          <FaCheck
                            className={`text-${
                              task.selected ? "white" : "gray-400"
                            }`}
                            size={10}
                          />
                        </span>
                      </label>

                      <div
                        className="w-[100%]"
                        onClick={() => {
                          setSelectedTask({
                            name: task.name,
                            stageIndex: stageIndex,
                            taskIndex: taskIndex,
                          });
                          setIsTaskClicked(true);
                        }}
                      >
                        <input
                          type="text"
                          value={task.name}
                          onChange={(e) => {
                            handleTaskNameChange(
                              stageIndex,
                              taskIndex,
                              e.target.value
                            );
                          }}
                          placeholder={`Task ${taskIndex + 1}`}
                          className="ml-6 p-1 border-none outline-none w-full"
                        />
                      </div>
                      <div
                        className="d-flex align-items-center ml-[24px] mr-[-5px]"
                        style={{ flexWrap: "nowrap" }}
                      >
                        {task.members &&
                        Array.isArray(task.members) &&
                        task.members.length > 0 ? (
                          <>
                            {task.members.slice(0, 2).map((memberId) => {
                              const member = users.find(
                                (user) => user.id === memberId
                              );
                              return (
                                <Badge
                                  key={memberId}
                                  bg="primary"
                                  text="light"
                                  className="me-1"
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  {member
                                    ? `${member.firstName} ${member.lastName}`
                                    : memberId}
                                </Badge>
                              );
                            })}
                            {task.members.length > 2 && (
                              <Badge
                                bg="secondary"
                                text="light"
                                className="me-1"
                                style={{ whiteSpace: "nowrap" }}
                              >
                                +{task.members.length - 2}
                              </Badge>
                            )}
                          </>
                        ) : null}
                      </div>

                      <div className="group-hover:flex hidden">
                        <Button
                          onClick={() => deleteTask(stageIndex, taskIndex)}
                          className="ml-3 p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                          variant="link"
                        >
                          <AiOutlineDelete
                            size={18}
                            className="text-red-500 hover:bg-red-200"
                          />
                        </Button>
                      </div>
                      <Button
                        onClick={() => addSubtask(stageIndex, taskIndex)}
                        className="p-1 hover:bg-green-200 rounded-full text-green-500 hover:text-green-600"
                        variant="link"
                      >
                        <AiOutlinePlus
                          size={16}
                          className="text-green-500 hover:bg-green-200"
                        />
                      </Button>
                    </div>
                    {expandedTasks[stageIndex]?.includes(taskIndex) && (
                      <div className="pl-8 ml-4">
                        {task.subtasks.map((subtask, subtaskIndex) => {
                          if (
                            [
                              "description",
                              "endDate",
                              "startDate",
                              "selectedMembers",
                              "members",
                            ].includes(subtask.name)
                          ) {
                            return null;
                          }
                          return (
                            <div
                              key={subtaskIndex}
                              className="flex items-center group"
                            >
                              <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={subtask.selected}
                                  onChange={() =>
                                    handleSubtaskSelectionChange(
                                      stageIndex,
                                      taskIndex,
                                      subtaskIndex
                                    )
                                  }
                                  className="sr-only"
                                />
                                <span
                                  className={`flex items-center justify-center h-6 w-6 border-3 rounded-full transition-colors ml-6 ${
                                    subtask.selected
                                      ? "bg-green-500 border-green-500"
                                      : "bg-transparent border-gray-300"
                                  }`}
                                >
                                  <FaCheck
                                    className={`text-${
                                      subtask.selected ? "white" : "gray-400"
                                    }`}
                                    size={10}
                                  />
                                </span>
                              </label>
                              <input
                                type="text"
                                value={subtask.name}
                                onChange={(e) =>
                                  handleSubtaskNameChange(
                                    stageIndex,
                                    taskIndex,
                                    subtaskIndex,
                                    e.target.value
                                  )
                                }
                                placeholder={`Subtask ${subtaskIndex + 1}`}
                                className="ml-6 p-1 border-none outline-none w-full"
                              />
                              {/* Show delete icon on hover */}
                              <div className="group-hover:flex hidden">
                                <Button
                                  onClick={() =>
                                    deleteSubtask(
                                      stageIndex,
                                      taskIndex,
                                      subtaskIndex
                                    )
                                  }
                                  className="p-1 hover:bg-red-200 rounded-full border-0 bg-transparent"
                                  variant="link"
                                >
                                  <AiOutlineDelete
                                    size={18}
                                    className="text-red-500 hover:bg-red-200 mr-6"
                                  />
                                </Button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-end space-x-2 pb-3">
          <Button
            className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
            id="theme-button"
            variant="primary"
            onClick={saveJson}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
          <Button
            id="theme-button"
            variant="outline-primary"
            onClick={addStage}
          >
            <AiOutlinePlus />
          </Button>
        </div>
      </div>
    </>
  );
};

export default PipelineModal;
