import React, { useState, useEffect } from "react";
import { useData } from "@Contexts/DataContext";
import {
  doc,
  updateDoc,
  deleteDoc,
  writeBatch,
  addDoc,
  collection,
} from "firebase/firestore";
import { db } from "@Config/firebase";
import { Table, Form, Button, Modal, FloatingLabel } from "react-bootstrap";
import { AiFillEdit, AiFillDelete, AiOutlinePlus } from "react-icons/ai"; // Import AiOutlinePlus for the plus icon
import "bootstrap/dist/css/bootstrap.min.css";

const ConfirmDelete = ({ show, handleClose, id }: any) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this place?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={async () => {
            await deleteDoc(doc(db, `places/${id}`));
            handleClose();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const TopRetail: React.FC = () => {
  const { place } = useData();
  const [globalToggle, setGlobalToggle] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    name: "",
    type: "",
    status: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    if (place.length === 0) return;

    const allSelected = place.every((item) => item.status);
    const noneSelected = place.every((item) => !item.status);

    if (allSelected) {
      setGlobalToggle(true);
    } else if (noneSelected) {
      setGlobalToggle(false);
    } else {
      setGlobalToggle(false);
    }
  }, [place]);

  const handleToggle = async (id: string, currentStatus: boolean) => {
    try {
      const placeRef = doc(db, "places", id);
      await updateDoc(placeRef, { status: !currentStatus });
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };

  const handleGlobalToggle = async () => {
    try {
      const newGlobalStatus = !globalToggle;
      setGlobalToggle(newGlobalStatus);

      const batch = writeBatch(db);

      place.forEach((item) => {
        const placeRef = doc(db, "places", item.id);
        batch.update(placeRef, { status: newGlobalStatus });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating all statuses: ", error);
    }
  };

  const handleDeleteClick = (id: string) => {
    setDeleteId(id);
    setShowConfirmModal(true);
  };

  const handleSave = async () => {
    try {
      if (isEditing) {
        const placeRef = doc(db, "places", modalData.id);
        await updateDoc(placeRef, {
          name: modalData.name,
          type: modalData.type,
          status: modalData.status,
        });
      } else {
        await addDoc(collection(db, "places"), {
          name: modalData.name,
          type: modalData.type,
          status: modalData.status,
        });
      }
      setShowModal(false);
    } catch (error) {
      console.error("Error saving item: ", error);
    }
  };

  const handleEdit = (item: any) => {
    setModalData(item);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleAddNew = () => {
    setModalData({ id: "", name: "", type: "", status: false });
    setIsEditing(false);
    setShowModal(true);
  };

  return (
    <div className="pl-64 mt-4 mx-4">
      <div className="flex items-center mb-3">
        <Button
          id="theme-button"
          variant="primary"
          onClick={handleAddNew}
          className="mr-2 mb-1"
        >
          <AiOutlinePlus size={24} />
        </Button>
        <h2 className="font-bold text-cprimary ml-2">Top Retail</h2>
      </div>
      <hr style={{ border: "2px solid rgb(255 29 127)" }} />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="w-[3%]">#</th>
            <th className="w-[30%]">Name</th>
            <th className="w-[30%]">Type</th>
            <th className="w-[17%]">
              <div className="flex items-center justify-between">
                <span>Status</span>
                <Form.Check
                  type="switch"
                  reverse
                  className="text-white"
                  checked={globalToggle}
                  onChange={handleGlobalToggle}
                  label="Toggle All"
                />
              </div>
            </th>
            <th className="w-[10%]">Actions</th>
          </tr>
        </thead>
        <tbody>
          {place.map((item, index) => (
            <tr key={item.id}>
              <td className="w-[3%]">{index + 1}</td>
              <td className="w-[33%]">{item.name}</td>
              <td className="w-[33%]">{item.type}</td>
              <td className="w-[5%]">
                <Form.Check
                  type="switch"
                  reverse
                  className="text-white inline-block m-0"
                  checked={item.status}
                  onChange={() => handleToggle(item.id, item.status)}
                />
              </td>
              <td className="w-[10%]">
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => handleEdit(item)}
                  className="mr-2"
                >
                  <AiFillEdit />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteClick(item.id)}
                >
                  <AiFillDelete />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Place" : "Add New Place"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FloatingLabel label="Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={modalData.name}
                onChange={(e) =>
                  setModalData({ ...modalData, name: e.target.value })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Type" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter type"
                value={modalData.type}
                onChange={(e) =>
                  setModalData({ ...modalData, type: e.target.value })
                }
              />
            </FloatingLabel>
            <Form.Group controlId="formStatus" className="mb-3">
              <Form.Check
                type="switch"
                label="Status"
                checked={modalData.status}
                onChange={(e) =>
                  setModalData({ ...modalData, status: e.target.checked })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="theme-button"
            variant="primary"
            onClick={handleSave}
            className="w-100"
          >
            {isEditing ? "Save Changes" : "Create Place"}
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmDelete
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        id={deleteId}
      />
    </div>
  );
};

export default TopRetail;
