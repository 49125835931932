import React, { useState, useEffect, useRef } from "react";
import { AiFillEdit, AiFillDelete, AiOutlinePlus } from "react-icons/ai";
import { Table, Form, Button, Modal, FloatingLabel } from "react-bootstrap";
import { useData } from "@Contexts/DataContext";
import { db, storage } from "@Config/firebase";
import {
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  collection,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import axios from "axios"; // Import axios for API calls
import { GoogleMap, Polygon, HeatmapLayerF } from "@react-google-maps/api";
import "bootstrap/dist/css/bootstrap.min.css";
import html2canvas from "html2canvas";
import Demographics from "@Components/Demographics";

const ConfirmDelete = ({ show, handleClose, id }: any) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this isochrone?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={async () => {
            await deleteDoc(doc(db, `isochrones/${id}`));
            handleClose();
          }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Isochrone: React.FC = () => {
  const { isochrones } = useData();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    id: "",
    name: "",
    price: 0,
    geojsonFileName: "",
    points: "",
    image: "",
    demographics: "",
    nse: "",
    description: "", // New description field
    populationOverlay: "",
  });
  const [populationData, setPopulationData] = useState<PopulationData[]>([]);
  const refPoly = useRef<Polygon | null>(null);
  const [_heatMapLoading, setHeatMapLoading] = useState(false); // State for loading status
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [refHeatMap, setRefHeatMap] =
    useState<null | google.maps.visualization.HeatmapLayer>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [coordinates, setCoordinates] = useState<
    Array<{ lng: number; lat: number }>
  >([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [nse, setNSE] = useState<any>({});
  const [demographics, setDemographics] = useState<any>({});
  const captureMapImage = async () => {
    if (!isMapLoaded) {
      console.warn("Map is not fully loaded yet");
      return null;
    }

    const mapElement = document.querySelector(".map-container") as HTMLElement;

    // Wait for tiles to load
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Use html2canvas to capture the map container
    const canvas = await html2canvas(mapElement, {
      useCORS: true, // Allow cross-origin images
      background: undefined, // Set the background to transparent
    });

    const mapImageBlob = await new Promise<Blob | null>((resolve) =>
      canvas.toBlob(resolve)
    );

    if (mapImageBlob) {
      const imageFileRef = ref(
        storage,
        `isochrones/map_images/${
          modalData.id || selectedFile?.name || "new"
        }.png`
      );
      await uploadBytes(imageFileRef, mapImageBlob);
      const imageUrl = await getDownloadURL(imageFileRef);
      return imageUrl;
    }

    return null;
  };

  useEffect(() => {
    setHeatMapLoading(true); // Start loading the heatmap
    if (coordinates.length) {
      setPopulationData([]); // Clear previous population data
      refHeatMap?.setMap(null); // Remove the existing heatmap from the map

      (async () => {
        // Extract latitude and longitude from coordinates
        let polygonLats = coordinates.map((cords) => cords.lat);
        let polygonLngs = coordinates.map((cords) => cords.lng);

        // Prepare data for the API request
        const data = {
          maxLat: Math.max(...polygonLats),
          minLat: Math.min(...polygonLats),
          maxLng: Math.max(...polygonLngs),
          minLng: Math.min(...polygonLngs),
        };

        try {
          // Make a POST request to fetch population data
          let response = await axios.post(
            `${import.meta.env.VITE_API_URL}/overlays/population`,
            data
          );

          if (response.status === 200) {
            // Create a Google Maps Polygon instance
            const polygon = new window.google.maps.Polygon({
              paths: coordinates.map(
                (coord) => new window.google.maps.LatLng(coord.lat, coord.lng)
              ),
            });

            // Filter population data based on the polygon
            const filteredPopulationData = response.data
              .filter((population: any) => {
                const latLng = new window.google.maps.LatLng({
                  lat: population.latitude,
                  lng: population.longitude,
                });
                return window.google.maps.geometry.poly.containsLocation(
                  latLng,
                  polygon
                );
              })
              .map((population: any) => ({
                location: new window.google.maps.LatLng({
                  lat: population.latitude,
                  lng: population.longitude,
                }),
                weight: population["population_2020"], // Use population data as weight
              }));

            setPopulationData(filteredPopulationData); // Update the state with filtered data
          }
        } catch (error) {
          console.error("Error fetching population data: ", error);
        } finally {
          setHeatMapLoading(false); // End loading regardless of success or failure
        }
      })();
    } else {
      // If there are no coordinates, clear population data and heatmap
      setPopulationData([]);
      refHeatMap?.setMap(null);
      setHeatMapLoading(false);
    }
  }, [coordinates]); // Dependency array: runs when `coordinates` changes
  console.log(populationData);

  const handleSave = async () => {
    setLoading(true);
    try {
      let mapImageUrl = modalData.image || "";

      // Handle file upload for geojson file
      if (selectedFile) {
        const fileRef = ref(storage, `isochrones/${selectedFile.name}`);
        await uploadBytes(fileRef, selectedFile);
      }

      // Capture and upload map image
      if (coordinates.length > 0) {
        mapImageUrl = (await captureMapImage()) || "";
      }

      const sanitizedPopulationData = populationData.map((item) => ({
        location: item.location.toJSON(), // Convert LatLng to a simple object
        weight: item.weight,
      }));

      if (isEditing) {
        const isochroneRef = doc(db, "isochrones", modalData.id);
        await updateDoc(isochroneRef, {
          name: modalData.name,
          price: modalData.price,
          points: coordinates,
          geojsonFileName: selectedFile
            ? selectedFile.name
            : modalData.geojsonFileName,
          image: mapImageUrl, // Save map image URL
          demographics: demographics,
          nse: nse,
          description: modalData.description,
          populationOverlay: sanitizedPopulationData,
        });
      } else {
        await addDoc(collection(db, "isochrones"), {
          name: modalData.name,
          price: modalData.price,
          points: coordinates,
          geojsonFileName: selectedFile
            ? selectedFile.name
            : modalData.geojsonFileName,
          image: mapImageUrl, // Save map image URL
          demographics: demographics,
          nse: nse,
          description: modalData.description,
          populationOverlay: sanitizedPopulationData,
        });
      }

      setShowModal(false);
      setSelectedFile(null);
      setCoordinates([]);
    } catch (error) {
      console.error("Error saving isochrone: ", error);
    } finally {
      setLoading(false); // End loading
    }
  };
  const handleEdit = async (item: any) => {
    setModalData(item);
    setIsEditing(true);
    setShowModal(true);
    setCoordinates([]);

    // Fetch the GeoJSON file content and parse the coordinates
    if (item.geojsonFileUrl) {
      console.log("Fetching GeoJSON from URL:", item.geojsonFileUrl);
      try {
        const response = await fetch(item.geojsonFileUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const content = await response.json();

        // Ensure the GeoJSON structure is as expected
        if (content.features && content.features.length > 0) {
          const coords = content.features[0].geometry.coordinates[0].map(
            (coord: [number, number]) => ({
              lng: coord[0],
              lat: coord[1],
            })
          );
          setCoordinates(coords); // Set the coordinates for the polygon
        }
      } catch (error) {
        console.error("Error loading GeoJSON file content: ", error);
      }
    }
  };

  const handleAddNew = () => {
    setModalData({
      id: "",
      name: "",
      price: 0,
      geojsonFileName: "",
      points: "",
      image: "",
      demographics: "",
      nse: "",
      description: "", // New description field
      populationOverlay: "",
    });
    setIsEditing(false);
    setCoordinates([]);
    setShowModal(true);
  };

  const handleDeleteClick = (id: string) => {
    setDeleteId(id);
    setShowConfirmModal(true);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setIsFileUploaded(true); // Set file upload status to true
      const fileContent = await file.text();
      const json = JSON.parse(fileContent);
      const coords = json.features[0].geometry.coordinates[0].map(
        (coord: [number, number]) => ({
          lng: coord[0],
          lat: coord[1],
        })
      );
      setCoordinates(coords);
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setCoordinates([]); // Clear coordinates when modal closes
  };

  return (
    <div className="pl-64 mt-4 mx-4">
      <div className="flex items-center mb-3">
        <Button
          id="theme-button"
          variant="primary"
          onClick={handleAddNew}
          className="mr-2 mb-1"
        >
          <AiOutlinePlus size={24} />
        </Button>
        <h2 className="font-bold text-cprimary ml-2">Polygons</h2>
      </div>
      <hr className="border-pink-500" />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className="w-[3%]">#</th>
            <th className="w-[14%]">Name</th>
            <th className="w-[14%]">Price</th>
            <th className="w-[30%]">Description</th>
            <th className="w-[8%]">Actions</th>
          </tr>
        </thead>
        <tbody>
          {isochrones.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{item.price}</td>
              <td>{item.description || "No file uploaded"}</td>
              <td>
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => handleEdit(item)}
                  className="mr-2"
                >
                  <AiFillEdit />
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDeleteClick(item.id)}
                >
                  <AiFillDelete />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Isochrone" : "Add Isochrone"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FloatingLabel label="Name" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={modalData.name}
                onChange={(e) =>
                  setModalData({ ...modalData, name: e.target.value })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Description" className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Enter a description"
                value={modalData.description}
                onChange={(e) =>
                  setModalData({ ...modalData, description: e.target.value })
                }
                style={{ height: "100px" }} // Adjust height for the textarea
              />
            </FloatingLabel>

            <FloatingLabel label="Price" className="mb-3">
              <Form.Control
                type="number"
                step="0.01"
                placeholder="Enter price "
                value={modalData.price}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (!isNaN(value)) {
                    setModalData({
                      ...modalData,
                      price: Math.round(value * 100) / 100,
                    });
                  }
                }}
              />
            </FloatingLabel>

            <Form.Group controlId="formFile" className="mb-3">
              {modalData.geojsonFileName && (
                <div className="mb-2">
                  <strong>Uploaded File:</strong>{" "}
                  <a
                    style={{ color: "#ff1d7f" }} // Change text color to pink
                  >
                    {modalData.geojsonFileName}
                  </a>
                </div>
              )}
              <Form.Control type="file" onChange={handleFileChange} />
            </Form.Group>

            {modalData.image && coordinates.length === 0 && (
              <div>
                <img
                  src={modalData.image}
                  alt="Saved map image"
                  style={{ width: "100%", height: "280px" }}
                />
              </div>
            )}

            {coordinates.length > 0 && (
              <div className="map-container" style={{ height: "280px" }}>
                {" "}
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "100%" }} // Set map to fill the container
                  zoom={14}
                  center={coordinates[0]}
                  onTilesLoaded={() => setIsMapLoaded(true)} // Set the state when map tiles are loaded
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                  }}
                  onLoad={() => {
                    setIsMapLoaded(true);
                    // Delay capture after map is loaded
                    setTimeout(captureMapImage, 1000); // Adjust the timeout as needed
                  }}
                >
                  <Polygon
                    path={coordinates}
                    ref={refPoly}
                    options={{
                      fillColor: "#ff1d7f",
                      fillOpacity: 0.15,
                      strokeWeight: 3,
                      strokeColor: "#ff1d7f",
                    }}
                  />
                  {populationData.length && (
                    <HeatmapLayerF
                      onLoad={(e: any) => setRefHeatMap(e)}
                      data={populationData}
                      options={{ opacity: 0.5 }}
                    />
                  )}
                </GoogleMap>
                <div style={{ display: "none" }}>
                  <Demographics
                    isochrone={coordinates.map((coord) => [
                      coord.lng,
                      coord.lat,
                    ])}
                    nse={nse}
                    setNSE={setNSE}
                    demographics={demographics}
                    setDemographics={setDemographics}
                    purchasedIsochrone={null}
                  />
                </div>
              </div>
            )}
            {!loading &&
              isFileUploaded &&
              (Object.keys(nse).length === 0 ||
                Object.keys(demographics).length === 0) && (
                <span className="text-danger">
                  Please wait for NSE and demographics data to load.
                </span>
              )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            disabled={
              !isEditing &&
              (loading ||
                Object.keys(nse).length === 0 ||
                Object.keys(demographics).length === 0)
            }
            variant="primary"
            onClick={handleSave}
            id="theme-button"
            className="w-100"
          >
            {loading
              ? isEditing
                ? "Updating..."
                : "Saving..."
              : isEditing
              ? "Update"
              : "Save"}{" "}
          </Button>
        </Modal.Footer>
      </Modal>

      <ConfirmDelete
        show={showConfirmModal}
        handleClose={() => setShowConfirmModal(false)}
        id={deleteId}
      />
    </div>
  );
};

export default Isochrone;
