import { useAuth } from "@Contexts/AuthContext";
import { useData } from "@Contexts/DataContext";
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

const urls = {
  public: ["/", "/login", "/signup", "/invitation"],
  manager: [
    "/",
    "/dashboard",
    "/manager/search",
    "/account-manager",
    "/broker/venue-add",
    "/profile",
    "/manager/explore",
    "/manager/projects",
    "/manager/models",
    "/manager/market",
    "/manager/organizations",
  ],
  admin: [
    "/",
    "/dashboard",
    "/admin/top_retail",
    "/admin/templates",
    "/admin/polygons",
    "/admin/databases/categories",
    "/admin/databases",
  ],
  broker: ["/", "/dashboard", "/broker/venue-add"],
};

const ProtectedRoute: React.FC<any> = ({ children, role, inverse }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { invitations, allOrganizations, retails, projects } = useData();

  useEffect(() => {
    urls.public = [
      ...urls.public,
      ...invitations.map((invitation) => `/invitation/${invitation.id}`),
    ];

    urls.manager = [
      ...urls.manager,
      ...allOrganizations.map((org) => `/manager/organizations/${org.id}`),
      ...projects.map((project) => `/manager/projects/${project.id}`),
    ];

    urls.admin = [
      ...urls.admin,
      ...retails.map((database) => `/admin/databases/${database.id}`),
    ];

    if (inverse && user) return navigate("/dashboard");
    if (inverse && !user) return;
    if (!role && !user) return navigate("/login");
    if (user && !(urls as any)[user.data.role].includes(location.pathname))
      return navigate("/dashboard");
  }, [user, location.pathname]);

  if (inverse && !role) {
    return <>{!user ? <>{children}</> : <NotAllowed />}</>;
  } else if (!inverse && !role) {
    return <>{user ? <>{children}</> : <NotAllowed />}</>;
  } else {
    return (
      <>
        {user && (urls as any)[user.data.role].includes(location.pathname) ? (
          <>{children}</>
        ) : (
          <NotAllowed />
        )}
      </>
    );
  }
};

const NotAllowed = () => {
  const navigate = useNavigate();
  return (
    <div className="w-screen bg-csecondary h-[calc(100vh-70px)] grid place-items-center">
      <div className="grid place-items-center">
        <h1 className="font-bold">Not Allowed</h1>
        <h3 className="font-bold">
          You don't seem to be allowed to visit this page.
        </h3>
        <br />
        <Button id="theme-button" onClick={() => navigate("/")}>
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default ProtectedRoute;
