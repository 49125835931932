import React, { useEffect, useState } from "react";
import { Alert, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import {
  AiOutlineDown,
  AiOutlineInfoCircle,
  AiOutlineLoading,
} from "react-icons/ai";
import { BsGenderFemale, BsGenderMale } from "react-icons/bs";
import { GoAlert } from "react-icons/go";
import WorkerFactory from "../utility/WorkerFactory";
// @ts-expect-error
import FetchWorker from "@Workers/fetch.worker";

const Demographics: React.FC<DemographicsProps> = ({
  isochrone,
  purchasedIsochrone,
  nse,
  setNSE,
  demographics,
  setDemographics,
}) => {
  const [NSEHover, setNSEHover] = useState<any>({});
  const [NSELoading, setNSELoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>("");

  const [demoLoading, setDemoLoading] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const getNSE = () => {
    let worker: Worker | null = null;
    if (worker === null && window.Worker && !NSELoading) {
      setNSELoading(true);
      worker = new WorkerFactory(FetchWorker) as Worker;

      worker.postMessage({
        url: `${(import.meta as any).env.VITE_API_URL}/overlays/nse`,
        options: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            polygon: isochrone.map((cords: any) => [cords[1], cords[0]]),
          }),
        },
      });

      worker.onerror = () => {
        setErr("Socioeconomic data could not be found for this area.");
      };

      worker.onmessageerror = () => {
        setErr("Socioeconomic data could not be found for this area.");
      };

      worker.addEventListener("messageerror", () => {
        setErr("Socioeconomic data could not be found for this area.");
      });
      worker.addEventListener("error", () => {
        setErr("Socioeconomic data could not be found for this area.");
      });

      worker.onmessage = (msg) => {
        const data = msg.data.data;

        if (msg.data?.type === "error") {
          setErr("Socioeconomic data could not be found for this area.");
          return;
        }

        const mappedData = data.map((item: any) => ({
          AB: Number(item.AB) || 0,
          C: Number(item.C) || 0,
          C_: Number(item.C_) || 0,
          C__10: Number(item.C__10) || 0,
          D: Number(item.D) || 0,
          D_: Number(item.D_) || 0,
          E: Number(item.E) || 0,
        }));

        if (msg.data?.agebs?.length > 0) {
          getDemographics(msg.data.agebs);
        }

        const reducer = (a: any, b: any) => {
          return a + b;
        };

        const AB = mappedData.map((data: any) => data.AB).reduce(reducer, 0);
        const C_ = mappedData.map((data: any) => data.C_).reduce(reducer, 0);
        const C = mappedData.map((data: any) => data.C).reduce(reducer, 0);
        const C__10 = mappedData
          .map((data: any) => data.C__10)
          .reduce(reducer, 0);
        const D_ = mappedData.map((data: any) => data.D_).reduce(reducer, 0);
        const D = mappedData.map((data: any) => data.D).reduce(reducer, 0);
        const E = mappedData.map((data: any) => data.E).reduce(reducer, 0);

        setNSE({
          total: AB + C_ + C + C__10 + D_ + D + E,
          AB,
          C_,
          C,
          C__10,
          D_,
          D,
          E,
        });

        setNSELoading(false);
        if (worker) worker.terminate();
      };
    }
  };

  const getDemographics = (agebs: string[]) => {
    let worker: Worker | null = null;
    if (worker === null && window.Worker && !demoLoading) {
      setDemoLoading(true);
      worker = new WorkerFactory(FetchWorker) as Worker;

      worker.postMessage({
        url: `${(import.meta as any).env.VITE_API_URL}/overlays/demographics`,
        options: {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            agebs,
          }),
        },
      });

      worker.onerror = () => {
        setErr("Demographics data could not be found for this area.");
      };

      worker.onmessageerror = () => {
        setErr("Demographics data could not be found for this area.");
      };

      worker.onmessage = (msg) => {
        const data = msg.data?.data;

        if (msg.data?.type === "error")
          setErr("Demographics data could not be found for this area.");

        if (data) setDemographics(data);

        setDemoLoading(false);
        if (worker) worker.terminate();
      };
    }
  };

  useEffect(() => {
    setNSE({});
    setDemographics({});
    if (isochrone.length >= 4 && !purchasedIsochrone) {
      getNSE();
    } else {
      setNSE(purchasedIsochrone.nse);
      setDemographics(purchasedIsochrone.demographics);
    }
  }, [isochrone.length, purchasedIsochrone]);

  const color: any = {
    AB: "success",
    C_: "primary",
    C: "info",
    C__10: "warning",
    D_: "danger",
    D: "secondary",
    E: "dark",
  };

  if (err) {
    return (
      <Alert
        variant="warning"
        className="flex gap-2 items-center text-sm"
        style={{ padding: "10px 15px" }}
      >
        <GoAlert />
        {err}
      </Alert>
    );
  }

  return (
    <>
      <div>
        {nse.total ? (
          <>
            <div className="bg-white p-2 rounded-xl flex items-center gap-2 w-[550px]">
              <ProgressBar max={nse.total} style={{ width: "100%" }}>
                {Object.keys(nse).map(
                  (key, i) =>
                    key !== "total" && (
                      <ProgressBar
                        variant={color[key as any]}
                        now={nse[key]}
                        onMouseOver={() => setNSEHover({ [key]: true })}
                        onMouseLeave={() => setNSEHover({ [key]: false })}
                        label={
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">{nse[key]} Houses</Tooltip>
                            }
                          >
                            <div>
                              {NSEHover?.[key]
                                ? `${((nse[key] / nse.total) * 100).toPrecision(
                                    2
                                  )}%`
                                : key.replace("__10", "-").replace("_", "+")}
                            </div>
                          </OverlayTrigger>
                        }
                        key={i}
                      />
                    )
                )}
              </ProgressBar>
              <AiOutlineDown
                onClick={() => setExpanded(!expanded)}
                style={{ rotate: expanded ? "180deg" : "0deg" }}
              />
            </div>
            {demographics?.males && expanded && (
              <div className="w-[550px] p-2 bg-white rounded-xl -mt-[14px]">
                <br />
                <div className="flex items-center gap-2">
                  <BsGenderMale />
                  <ProgressBar
                    max={demographics.males.total}
                    style={{ width: "100%" }}
                  >
                    {Object.keys(demographics.males).map(
                      (key, i) =>
                        key !== "total" && (
                          <ProgressBar
                            now={demographics.males[key]}
                            style={{
                              background: `rgb(${0 + i * 15}, ${0 + i * 15}, ${
                                0 + i * 15
                              })`,
                            }}
                            label={
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {key.split("_").join(" ")}
                                  </Tooltip>
                                }
                              >
                                <div>
                                  {Math.round(
                                    (demographics.males[key] /
                                      demographics.males.total) *
                                      100
                                  ) + "%"}
                                </div>
                              </OverlayTrigger>
                            }
                            key={i}
                          />
                        )
                    )}
                  </ProgressBar>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <BsGenderFemale />
                  <ProgressBar
                    max={demographics.females.total}
                    style={{ width: "100%" }}
                  >
                    {Object.keys(demographics.females).map(
                      (key, i) =>
                        key !== "total" && (
                          <ProgressBar
                            style={{
                              background: `rgb(${0 + i * 15}, ${0 + i * 15}, ${
                                0 + i * 15
                              })`,
                            }}
                            now={demographics.females[key]}
                            label={
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {key.split("_").join(" ")}
                                  </Tooltip>
                                }
                              >
                                <div>
                                  {Math.round(
                                    (demographics.females[key] /
                                      demographics.females.total) *
                                      100
                                  ) + "%"}
                                </div>
                              </OverlayTrigger>
                            }
                            key={i}
                          />
                        )
                    )}
                  </ProgressBar>
                </div>
                <br />
                <div className="flex gap-1 items-center">
                  <span className="font-bold">AUTOM: </span>
                  <span>{demographics.AUTOM}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-autom">
                        Private homes habited that have car or truck
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="flex gap-1 items-center">
                  <span className="font-bold">MOTO: </span>
                  <span>{demographics.MOTO}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-moto">
                        Private homes habited that have motorcycle or scooter
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="flex gap-1 items-center">
                  <span className="font-bold">BICI: </span>
                  <span>{demographics.BICI}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-bici">
                        Private homes habited that have bicycle as a means of
                        transportation
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="flex gap-1 items-center">
                  <span className="font-bold">PC: </span>
                  <span>{demographics.PC}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-pc">
                        Private homes habited that have computer, laptop or
                        tablet
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="flex gap-1 items-center">
                  <span className="font-bold">STVP: </span>
                  <span>{demographics.STVP}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-stvp">
                        Private homes habited that have television service paid
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="flex gap-1 items-center">
                  <span className="font-bold">SPMVPI: </span>
                  <span>{demographics.SPMVPI}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-spmvpi">
                        Private homes habited that have movie service, music or
                        videos paid online
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>

                <div className="flex gap-1 items-center">
                  <span className="font-bold">CVJ: </span>
                  <span>{demographics.CVJ}</span>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-cvj">
                        Private homes habited that have a video game console
                      </Tooltip>
                    }
                  >
                    <div>
                      <AiOutlineInfoCircle />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            )}
          </>
        ) : err ? (
          <Alert variant="warning" className="flex gap-2 items-center">
            <GoAlert />
            {err}
          </Alert>
        ) : (
          <>
            {NSELoading && (
              <AiOutlineLoading className="animate-spin text-sm mt-2 ml-3 text-white" />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Demographics;
