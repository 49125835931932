import {
  addDoc,
  collection,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import Search from "@Components/Search";
import {
  Button,
  Form,
  FloatingLabel,
  Table,
  Modal,
  Carousel,
} from "react-bootstrap";
import { db, storage } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useData } from "@Contexts/DataContext";
import { GoogleMap, Marker } from "@react-google-maps/api";

export default function VenueAdd({ isLoaded }: any) {
  const [show, setShow] = useState(false);
  const [refreshVal, setRefreshVal] = useState(0);

  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  if (!user?.email) {
    return <>User not logged in</>;
  } else
    return (
      <div
        // className="container"
        style={{
          paddingLeft: 320,
          paddingRight: 70,
          padding: "0px 70px 0px 320px",
        }}
      >
        <div className="main-content">
          <h1 className="text-center mt-5">Public Locales</h1>
          <p className="text-center"></p>
        </div>
        <br />
        {isLoaded && (
          <AddVenueModal
            show={show}
            handleClose={() => setShow(false)}
            refresh={() => setRefreshVal(Math.random())}
          />
        )}
        <LocaleTable
          newLocalHandleShow={() => setShow(true)}
          refresh={() => setRefreshVal(Math.random())}
          refreshVal={refreshVal}
        />
      </div>
    );
}

const AddVenueModal = ({ show, handleClose, refresh }: any) => {
  const [images, setImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [_visibility, setVisibility] = useState<string>("");
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    { id: string; name: string }[]
  >([]);
  const [_organizationNotFound, setOrganizationNotFound] =
    useState<boolean>(false);

  const { user } = useAuth();
  const { allOrganizations } = useData();

  const [values, setValues] = useState({
    name: "",
    front: 0,
    bottom: 0,
    sqMeter: 0,
    price: 0,
    haveParking: false,
    isCorner: false,
    floors: 1,
    commercial: false,
    location: {
      address: "",
      longitude: -96.9385916,
      latitude: 18.8975617,
    },
    visibility: [],
  });

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Locale</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              setLoading(true);
              try {
                let imgUrls = [];

                const localeRef = collection(db, `locales`);

                for (let i = 0; i < images.length; i++) {
                  const image = images[i];

                  const imgRef = ref(
                    storage,
                    `User-Data/locales/${localeRef.id}/image-${
                      image?.lastModified
                    }.${image.type.split("/")[1]}`
                  );
                  const imgSnapshot = await uploadBytes(imgRef, image);
                  const downloadURL = await getDownloadURL(imgSnapshot.ref);
                  imgUrls.push(downloadURL);
                }
                await addDoc(localeRef, {
                  ...values,
                  userId: user.uid,
                  images: imgUrls,
                  visibility: selectedOrganizations.map((org) => org.id),
                });
                refresh();
                handleClose();
              } catch (err) {
                console.error(err);
              }
              setLoading(false);
            }}
            style={{ margin: "auto" }}
            action=""
            id="form-publilocal"
          >
            <div className="flex flex-col gap-2">
              <FloatingLabel label="Nombre del Local">
                <Form.Control
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setValues({ ...values, name: e.target.value })
                  }
                />
              </FloatingLabel>

              <FloatingLabel label="Address">
                <Form.Control
                  type="text"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setValues({
                      ...values,
                      location: { ...values.location, address: e.target.value },
                    })
                  }
                />
              </FloatingLabel>
              <Form.Group>
                <Search
                  list={allOrganizations}
                  searchingProperty="name"
                  setAddingItems={setSelectedOrganizations}
                  addingItems={selectedOrganizations}
                  placeholder="Visibility (public if empty)"
                  max={9999}
                  onSearchTextChange={(text) => {
                    setVisibility(text);
                    setOrganizationNotFound(false);
                  }}
                  resetTrigger={null}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Select Location On Map</Form.Label>
                <GoogleMap
                  center={{
                    lat: values.location.latitude,
                    lng: values.location.longitude,
                  }}
                  options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    fullscreenControl: false,
                    zoomControl: false,
                  }}
                  onClick={(e) => {
                    setValues({
                      ...values,
                      location: {
                        ...values.location,
                        latitude: e.latLng?.lat() || 0,
                        longitude: e.latLng?.lng() || 0,
                      },
                    });
                  }}
                  mapContainerStyle={{
                    width: "100%",
                    height: 200,
                    borderRadius: 14,
                  }}
                  zoom={13}
                >
                  <Marker
                    position={{
                      lat: values.location.latitude,
                      lng: values.location.longitude,
                    }}
                  />
                </GoogleMap>
              </Form.Group>

              <div className="flex gap-2">
                <FloatingLabel label="Medida de Frente" className="w-1/2">
                  <Form.Control
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setValues({ ...values, front: Number(e.target.value) })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel label="Medida de fondo" className="w-1/2">
                  <Form.Control
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) =>
                      setValues({ ...values, bottom: Number(e.target.value) })
                    }
                  />
                </FloatingLabel>
              </div>

              <FloatingLabel label="Medida en Metros Cuadrados">
                <Form.Control
                  type="number"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setValues({ ...values, sqMeter: Number(e.target.value) })
                  }
                />
              </FloatingLabel>

              <FloatingLabel label="Precio del Local">
                <Form.Control
                  type="number"
                  className="form-control"
                  required
                  onChange={(e) =>
                    setValues({ ...values, price: Number(e.target.value) })
                  }
                />
              </FloatingLabel>

              <Form.Group>
                <Form.Label style={{ marginRight: 24 }}>
                  ¿Tu local tiene tiene lugar de estacionamiento?
                </Form.Label>
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-0`}
                  onClick={() => setValues({ ...values, haveParking: true })}
                  checked={values.haveParking}
                  label={`Yes`}
                />
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-0`}
                  onClick={() => setValues({ ...values, haveParking: false })}
                  checked={!values.haveParking}
                  label={`No`}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ marginRight: 24 }}>
                  El local esta en esquina?
                </Form.Label>
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-1`}
                  onClick={() => setValues({ ...values, isCorner: true })}
                  checked={values.isCorner}
                  label={`Yes`}
                />
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-1`}
                  onClick={() => setValues({ ...values, isCorner: false })}
                  checked={!values.isCorner}
                  label={`No`}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ marginRight: 24 }}>
                  Indica cuantas plantas tiene tu local
                </Form.Label>
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-2`}
                  onClick={() => setValues({ ...values, floors: 1 })}
                  checked={values.floors === 1}
                  label={`1`}
                />
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-2`}
                  onClick={() => setValues({ ...values, floors: 2 })}
                  checked={values.floors === 2}
                  label={`2`}
                />
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-2`}
                  onClick={() => setValues({ ...values, floors: 3 })}
                  checked={values.floors === 3}
                  label={`3`}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label style={{ marginRight: 24 }}>
                  ¿Tu local esta en plaza comercial?
                </Form.Label>
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-3`}
                  onClick={() => setValues({ ...values, commercial: true })}
                  checked={values.commercial}
                  label={`Yes`}
                />
                <Form.Check
                  type={"radio"}
                  inline
                  id={`default-radio-3`}
                  onClick={() => setValues({ ...values, commercial: false })}
                  checked={!values.commercial}
                  label={`No`}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Añada Fotos de Su local</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  // @ts-ignore
                  onChange={(e) => setImages([...e.target?.files])}
                  multiple
                  required
                />

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingTop: 18,
                    gap: 8,
                  }}
                >
                  {images.length ? (
                    images.map((image, idx) => (
                      <img
                        key={idx}
                        src={URL.createObjectURL(image)}
                        id="output"
                        width="100"
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              </Form.Group>

              <Button
                id="theme-button"
                className="w-100 btn btn-lg btn-warning"
                type="submit"
                disabled={loading}
              >
                Guardar Local
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const LocaleTable = ({ newLocalHandleShow, refresh }: any) => {
  const [show, setShow] = useState({ show: false, idx: 0 });
  const [imageShow, setImageShow] = useState({ show: false, images: [] });

  const { locales } = useData();

  return (
    <>
      <LocaleEditModal
        show={show.show}
        handleClose={() => setShow({ show: false, idx: 0 })}
        values={locales?.[show.idx]}
        id={locales?.[show.idx]?.id}
      />
      <Button
        onClick={newLocalHandleShow}
        style={{ marginBottom: 12 }}
        id="theme-button"
      >
        Upload Local
      </Button>
      <Table striped bordered>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Address</th>
            <th scope="col">Front</th>
            <th scope="col">Bottom</th>
            <th scope="col">Floors</th>
            <th scope="col">Sq. Meter</th>
            <th scope="col">Corner</th>
            <th scope="col">Parking</th>
            <th scope="col">Commercial</th>
            <th scope="col">Price</th>
            <th scope="col">Images</th>
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          {locales.map((locale: any, idx: number) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{locale.name}</td>
              <td>{locale?.location?.address || "N/A"}</td>
              <td>{locale.front}</td>
              <td>{locale.bottom}</td>
              <td>{locale.floors}</td>
              <td>{locale.sqMeter}</td>
              <td>{JSON.stringify(locale.isCorner)}</td>
              <td>{JSON.stringify(locale.haveParking)}</td>
              <td>{JSON.stringify(locale.commercial)}</td>
              <td>${locale.price}</td>
              <td>
                {locale.images && (
                  <Button
                    className="w-100"
                    variant={"link"}
                    size="sm"
                    onClick={() =>
                      setImageShow({ show: true, images: locale.images })
                    }
                  >
                    Show ({locale.images.length})
                  </Button>
                )}
              </td>
              <td style={{ display: "flex", gap: 8, justifyContent: "center" }}>
                <Button
                  variant={"danger"}
                  className="w-100"
                  size="sm"
                  onClick={async () => {
                    await deleteDoc(doc(db, `locales/${locale.id}`));
                    refresh();
                  }}
                >
                  <AiFillDelete />
                </Button>
                <Button
                  size="sm"
                  className="w-100"
                  onClick={() => setShow({ show: true, idx })}
                >
                  <FiEdit />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        <ImageModal
          images={imageShow.images}
          show={imageShow.show}
          handleClose={() => setImageShow({ show: false, images: [] })}
        />
      </Table>
    </>
  );
};

const ImageModal = ({ images, show, handleClose }: any) => {
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Locale Images: </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Carousel>
          {images.map((image: string, idx: number) => (
            <Carousel.Item key={idx}>
              <img width="100%" src={image} alt={`image-${idx}`} />
              <Carousel.Caption>
                <h4>Image #{idx + 1}</h4>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
};

const LocaleEditModal = ({ show, handleClose, values, id }: any) => {
  let [newValues, setNewValues] = useState(values);
  const [loading, setLoading] = useState(false);
  const [_visibilityEdit, setVisibilityEdit] = useState<string>("");
  const [_organizationNotFoundEdit, setOrganizationNotFoundEdit] =
    useState<boolean>(false);

  const [selectedOrganizationsEdit, setSelectedOrganizationsEdit] = useState<
    { id: string; name: string }[]
  >([]);

  const { allOrganizations, locales } = useData();

  useEffect(() => {
    const fetchLocaleVisibility = () => {
      try {
        const locale = locales.find((locale) => locale.id === id);

        if (locale) {
          const selectedOrgs = locale.visibility || [];
          const selectedOrgObjects = selectedOrgs.map((orgId: string) => {
            const matchingOrg = allOrganizations.find(
              (org) => org.id === orgId
            );
            return (
              matchingOrg || {
                id: "Organization not found",
                name: "Organization not found",
              }
            );
          });

          setSelectedOrganizationsEdit(selectedOrgObjects);
          setVisibilityEdit("");
        } else {
          setOrganizationNotFoundEdit(true);
        }
      } catch (error) {
        console.error("Error fetching locale organizations:", error);
      }
    };

    fetchLocaleVisibility();
  }, [id, locales, allOrganizations]);

  const updateLocale = async () => {
    setLoading(true);

    const updatedOrganizationIds = selectedOrganizationsEdit.map(
      (org) => org.id
    );

    const localeDoc = await getDoc(doc(db, `locales/${id}`));
    const localeData = localeDoc.data();
    const existingOrganizationIds = localeData?.allOrganizations || [];

    const combinedOrganizationIds = Array.from(
      new Set([...existingOrganizationIds, ...updatedOrganizationIds])
    );

    await updateDoc(doc(db, `locales/${id}`), {
      ...newValues,
      visibility: combinedOrganizationIds,
    });

    handleClose();
    setLoading(false);
  };

  useEffect(() => {
    setNewValues(values);
  }, [values]);

  if (!show) return <></>;

  return (
    <Modal show={show} size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Model</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-2">
          <FloatingLabel label="Model Name">
            <Form.Control
              type="email"
              className="form-control"
              required
              defaultValue={values.name}
              onChange={(e) =>
                setNewValues({ ...values, name: e.target.value })
              }
            />
          </FloatingLabel>

          <FloatingLabel label="Address">
            <Form.Control
              type="text"
              className="form-control"
              required
              onChange={(e) =>
                setNewValues({
                  ...values,
                  location: { ...values.location, address: e.target.value },
                })
              }
            />
          </FloatingLabel>

          <div className="flex gap-2">
            <FloatingLabel label="Front Measurement" className="w-1/2">
              <Form.Control
                type="text"
                className="form-control"
                required
                defaultValue={values.front}
                onChange={(e) =>
                  setNewValues({ ...values, front: e.target.value })
                }
              />
            </FloatingLabel>
            <FloatingLabel label="Bottom Measurement" className="w-1/2">
              <Form.Control
                type="text"
                className="form-control"
                required
                defaultValue={values.bottom}
                onChange={(e) =>
                  setNewValues({ ...values, bottom: e.target.value })
                }
              />
            </FloatingLabel>
          </div>
          <FloatingLabel label="Sq. Meter:">
            <Form.Control
              type="number"
              className="form-control"
              required
              defaultValue={values.sqMeter}
              onChange={(e) =>
                setNewValues({ ...values, sqMeter: e.target.value })
              }
            />
          </FloatingLabel>
          <FloatingLabel label="Price:">
            <Form.Control
              type="number"
              className="form-control"
              required
              defaultValue={values.price}
              onChange={(e) =>
                setNewValues({ ...values, price: e.target.value })
              }
            />
          </FloatingLabel>

          <Form.Group>
            <Form.Label style={{ marginRight: 24 }}>
              ¿Tu local tiene tiene lugar de estacionamiento?
            </Form.Label>
            <br />
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-0`}
              onClick={() => setNewValues({ ...values, haveParking: true })}
              checked={newValues?.haveParking}
              label={`Yes`}
            />
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-0`}
              onClick={() => setNewValues({ ...values, haveParking: false })}
              checked={!newValues?.haveParking}
              label={`No`}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ marginRight: 24 }}>
              El local esta en esquina?
            </Form.Label>
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-1`}
              onClick={() => setNewValues({ ...values, isCorner: true })}
              checked={newValues?.isCorner}
              label={`Yes`}
            />
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-1`}
              onClick={() => setNewValues({ ...values, isCorner: false })}
              checked={!newValues?.isCorner}
              label={`No`}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ marginRight: 24 }}>
              Indica cuantas plantas tiene tu local
            </Form.Label>
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-2`}
              onClick={() => setNewValues({ ...values, floors: 1 })}
              checked={newValues?.floors === 1}
              label={`1`}
            />
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-2`}
              onClick={() => setNewValues({ ...values, floors: 2 })}
              checked={newValues?.floors === 2}
              label={`2`}
            />
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-2`}
              onClick={() => setNewValues({ ...values, floors: 3 })}
              checked={newValues?.floors === 3}
              label={`3`}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label style={{ marginRight: 24 }}>
              ¿Tu local esta en plaza comercial?
            </Form.Label>
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-3`}
              onClick={() => setNewValues({ ...values, commercial: true })}
              checked={newValues?.commercial}
              label={`Yes`}
            />
            <Form.Check
              type={"radio"}
              inline
              id={`default-radio-3`}
              onClick={() => setNewValues({ ...values, commercial: false })}
              checked={!newValues?.commercial}
              label={`No`}
            />
          </Form.Group>
          <Form.Group>
            <Search
              list={allOrganizations}
              searchingProperty="name"
              setAddingItems={setSelectedOrganizationsEdit}
              addingItems={selectedOrganizationsEdit}
              placeholder="Visibility (public if empty)"
              max={9999}
              onSearchTextChange={(text) => {
                setVisibilityEdit(text);
                setOrganizationNotFoundEdit(false);
              }}
              resetTrigger={null}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Select Location On Map</Form.Label>
            <GoogleMap
              center={{
                lat: newValues?.location?.latitude || 0,
                lng: newValues?.location?.longitude || 0,
              }}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
              }}
              onClick={(e) => {
                setNewValues({
                  ...newValues,
                  location: {
                    ...newValues?.location,
                    latitude: e.latLng?.lat() || 0,
                    longitude: e.latLng?.lng() || 0,
                  },
                });
              }}
              mapContainerStyle={{
                width: "100%",
                height: 200,
                borderRadius: 14,
              }}
              zoom={13}
            >
              <Marker
                position={{
                  lat: newValues?.location?.latitude || 0,
                  lng: newValues?.location?.longitude || 0,
                }}
              />
            </GoogleMap>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="w-full"
          id="theme-button"
          onClick={updateLocale}
          disabled={loading}
        >
          Save Model
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
