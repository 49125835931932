import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useData } from "@Contexts/DataContext";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "@Config/firebase";
import { useAuth } from "@Contexts/AuthContext";
import { GoogleMap, Polygon, Marker } from "@react-google-maps/api";
import PipelineModal from "./pipelineModal";
import { FaParking, FaMapMarkerAlt } from "react-icons/fa";
import {
  BtnBold,
  BtnItalic,
  BtnUnderline,
  BtnBulletList,
  BtnNumberedList,
  BtnStyles,
  Editor,
  EditorProvider,
  Toolbar,
} from "react-simple-wysiwyg";

import { AiFillShop, AiOutlineClose } from "react-icons/ai";
import { Button } from "react-bootstrap";
import { BsBuildings } from "react-icons/bs";
import { RxCorners, RxCornerTopRight } from "react-icons/rx";
import { Form, Row, Col, FloatingLabel } from "react-bootstrap";

import axios from "axios";
import Search from "@Components/Search";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const deepMerge = (target: any, source: any): any => {
  try {
    if (typeof source !== "object" || source === null) {
      return source;
    }

    if (Array.isArray(source)) {
      return [...source];
    }

    const output = { ...target };

    for (const key of Object.keys(source)) {
      if (key === "members") {
        output[key] = source[key];
      } else if (
        source[key] &&
        typeof source[key] === "object" &&
        !Array.isArray(source[key])
      ) {
        output[key] = deepMerge(target[key] || {}, source[key]);
      } else {
        output[key] = source[key];
      }
    }

    for (const key of Object.keys(output)) {
      if (!(key in source)) {
        if (
          (typeof output[key] === "object" ||
            typeof output[key] === "boolean") &&
          key !== "members"
        ) {
          delete output[key];
        }
      }
    }

    return output;
  } catch (error) {
    console.error("Error during deep merge:", error);
  }
};

const EditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [taskDataSaved, setTaskDataSaved] = useState(false);
  const [isTaskClicked, setIsTaskClicked] = useState(false);
  const [center, setCenter] = useState({ lat: 18.8975617, lng: -96.9385916 });
  const { projects, organizations, locales, users } = useData();
  const [pipeline, setPipeline] = useState<any>(null);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { user } = useAuth();
  const [filteredLocales, setFilteredLocales] = useState<any[]>([]);
  const [polygonDrawn, setPolygonDrawn] = useState(false);
  const [showNoLocalesMessage, setShowNoLocalesMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [opacity, setOpacity] = useState(0);
  const [selectedTask, setSelectedTask] = useState<Taskupdate | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [description, setDescription] = useState("");
  const [organization, setOrganization] = useState<any>(null);
  const [members, setMembers] = useState<Member[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [databaseEmail, setDatebaseEmail] = useState<string[]>([]);
  const [membersIds, setMembersIds] = useState<string[]>([]);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [resetSearchTrigger, setResetSearchTrigger] = useState(0);

  useEffect(() => {
    if (selectedTask) {
      setResetSearchTrigger((prev) => prev + 1);
    }
  }, [selectedTask]);

  useEffect(() => {
    const newMembersArray = convertMembersArrayToIds(selectedMembers);
    setMembersIds(newMembersArray);
  }, [selectedMembers]);

  useEffect(() => {
    const userDefaultOrgId = user?.data?.default_organization;
    const org = organizations?.find((org) => org.id === userDefaultOrgId);
    setOrganization(org || null);
  }, [user?.data?.default_organization, organizations]);

  useEffect(() => {
    if (organization) {
      const membersWithRole =
        organization.members?.filter((mem: any) => mem.role === "member") || [];

      const membersWithDetails = membersWithRole.map((member: any) => {
        const userDetail = users.find((user) => user.id === member.uid);
        const fullName = userDetail
          ? `${userDetail.firstName} ${userDetail.lastName}`
          : "Unknown Member";
        const email = userDetail?.email || "Unknown Email";

        return { uid: member.uid, fullName, email };
      });

      setMembers(membersWithDetails);
    }
  }, [organization, users]);

  useEffect(() => {
    let timer: number | undefined;
    if (isTaskClicked) {
      timer = window.setTimeout(() => {
        setOpacity(1);
      }, 350);
    }
    return () => {
      if (timer !== undefined) {
        window.clearTimeout(timer);
      }
    };
  }, [isTaskClicked]);

  const divStyle = {
    width: "39%",
    opacity: opacity,
    transition: "opacity 0.5s ease",
    height: "90vh",
  };

  useEffect(() => {
    if (
      selectedTask &&
      typeof selectedTask.stageIndex === "number" &&
      typeof selectedTask.taskIndex === "number"
    ) {
      const updatedPipeline = JSON.parse(JSON.stringify(pipeline));
      const { stageIndex, taskIndex } = selectedTask;
      const stages = Object.keys(updatedPipeline);
      const stage = stages[stageIndex];
      const tasks = updatedPipeline[stage] as HierarchicalPipeline;

      if (tasks) {
        const taskKeys = Object.keys(tasks);
        const taskKey = taskKeys[taskIndex];
        const task = tasks[taskKey];

        if (typeof task === "object" && task !== null) {
          const { startDate, endDate, description, members } = task;

          setStartDate(typeof startDate === "string" ? startDate : "");
          setEndDate(typeof endDate === "string" ? endDate : "");
          setDescription(typeof description === "string" ? description : "");

          if (Array.isArray(members)) {
            const selectedMembers = users
              .filter((user) => {
                if (!user || !user.id) {
                  console.warn(
                    "User is undefined or does not have an id:",
                    user
                  );
                  return false;
                }
                return members.includes(user.id);
              })
              .map((user) => ({
                uid: user.id,
                fullName: `${user.firstName} ${user.lastName}`,
                email: user.email,
              }));

            setSelectedMembers(selectedMembers);
            setDatebaseEmail(selectedMembers.map((member) => member.email));
          } else {
            setSelectedMembers([]);
            setDatebaseEmail([]);
          }
        } else {
          setStartDate("");
          setEndDate("");
          setDescription("");
          setSelectedMembers([]);
        }
      }
    }
  }, [selectedTask, pipeline, users]);

  useEffect(() => {
    const project = projects.find((project: Project) => project.id === id);
    if (project) {
      setSelectedProject(project);
      setPipeline(project.pipeline || {}); // pipeline will now have HierarchicalPipeline type
      setModalOpen(true);

      if (project.isochrone?.length) {
        const firstCoordinate = project.isochrone[0];
        setCenter({
          lat: firstCoordinate.latitude,
          lng: firstCoordinate.longitude,
        });
      }
    }
  }, [id, projects]);

  useEffect(() => {
    if (selectedProject?.isochrone) {
      setPolygonDrawn(true);
    } else {
      setPolygonDrawn(false);
    }
  }, [selectedProject?.isochrone]);

  useEffect(() => {
    if (polygonDrawn && selectedProject?.isochrone && mapRef.current) {
      const isochrone = selectedProject.isochrone.map(
        (coord: { latitude: number; longitude: number }) => ({
          lat: coord.latitude,
          lng: coord.longitude,
        })
      );

      const updatedFilteredLocales = locales.filter(
        (locale: any) =>
          selectedProject?.localeIds?.includes(locale.id) &&
          isLocaleInPolygon(
            locale.location.latitude,
            locale.location.longitude,
            isochrone
          )
      );
      setFilteredLocales(updatedFilteredLocales);
      setShowNoLocalesMessage(updatedFilteredLocales.length === 0);
    }
  }, [polygonDrawn, selectedProject, locales, mapRef]);

  const convertMembersArrayToIds = (members: Member[]): string[] => {
    return members.map((member) => member.uid);
  };

  const onPipelineChange = (updatedPipeline: any) => {
    setPipeline(updatedPipeline);
  };

  const onSave = async (updatedPipeline: HierarchicalPipeline) => {
    if (selectedProject && organization) {
      try {
        const projectRef = doc(
          db,
          `organizations/${organization.id}/projects`,
          selectedProject.id
        );
        const projectDoc = await getDoc(projectRef);

        if (!projectDoc.exists()) {
          console.error("No such project document!");
          setAlertMessage("Project document does not exist.");
          setTimeout(() => setAlertMessage(null), 3000);
          return;
        }

        const existingData = projectDoc.data() || {};
        const mergedPipeline = deepMerge(
          existingData.pipeline || {},
          updatedPipeline
        );

        const updatedData = {
          ...existingData,
          pipeline: mergedPipeline,
        };

        await updateDoc(projectRef, updatedData);
        setPipeline(mergedPipeline);

        const databaseEmails: string[] = databaseEmail;
        const selectedMemberEmails = selectedMembers.map(
          (member) => member.email
        );

        const newEmailsList = selectedMemberEmails.filter(
          (email) => !databaseEmails.includes(email)
        );

        setAlertMessage("Changes saved successfully!");
        setTimeout(() => setAlertMessage(null), 3000);

        if (newEmailsList.length > 0) {
          await sendTaskAssignmentNotifications(newEmailsList);
        }
      } catch (e) {
        handleError(e, "Error updating project document.");
      }
    }
  };

  const sendTaskAssignmentNotifications = async (newEmailsList: string[]) => {
    try {
      const emailWithNames = newEmailsList.map((email) => {
        const member = selectedMembers.find((m) => m.email === email);
        return {
          email,
          fullName: member ? member.fullName : email,
        };
      });

      const batchSize = 5;
      for (let i = 0; i < emailWithNames.length; i += batchSize) {
        const batch = emailWithNames
          .slice(i, i + batchSize)
          .map(({ email, fullName }) =>
            axios.post(
              import.meta.env.VITE_API_URL + "/notifications/tasks/assigned",
              {
                sender: user?.data?.firstName
                  ? `${user.data.firstName} ${user?.data?.lastName}`
                  : user.email.split("@")[0],
                receivers: [email],
                task_title: selectedTask?.name,
                member: fullName,
                organization_name: organization.name,
              }
            )
          );

        await Promise.all(batch);
      }
    } catch (err) {
      handleError(err, "Error sending task assignment notifications.");
    }
  };

  const handleError = (err: unknown, message: string) => {
    console.error(message);
    if (err instanceof Error) {
      console.log(err.message);
      if ((err as any).response) {
        setAlertMessage(
          `${(err as any).response.status}: ${
            (err as any).response.data.message
          }`
        );
      } else {
        setAlertMessage("Internal server error occurred.");
      }
    } else {
      setAlertMessage("An unknown error occurred.");
    }
    setTimeout(() => setAlertMessage(null), 3000);
  };

  const onTaskSave = async (updatedTask: any) => {
    if (selectedProject && organization) {
      try {
        const taskIndex = selectedProject.tasks.findIndex(
          (task: any) => task.id === updatedTask.id
        );

        if (taskIndex !== -1) {
          const updatedProject = {
            ...selectedProject,
            tasks: [
              ...selectedProject.tasks.slice(0, taskIndex),
              updatedTask,
              ...selectedProject.tasks.slice(taskIndex + 1),
            ],
          };

          const projectRef = doc(
            db,
            `organizations/${organization.id}/projects`,
            selectedProject.id
          );

          await updateDoc(projectRef, {
            tasks: updatedProject.tasks,
          });

          setPipeline(updatedProject.pipeline || {});
          setAlertMessage("Changes saved successfully!");
          setTimeout(() => setAlertMessage(null), 3000);
        } else {
          console.error("Task not found in selectedProject.tasks");
        }
      } catch (e) {
        console.error("Error updating document: ", e);
      }
    } else {
      console.error("Selected project or organization is not defined");
    }
  };

  const handleRemoveLocale = async (localeId: string) => {
    if (selectedProject && organization) {
      try {
        const updatedLocaleIds = selectedProject.localeIds.filter(
          (id: string) => id !== localeId
        );
        const projectRef = doc(
          db,
          `organizations/${organization.id}/projects`,
          selectedProject.id
        );
        await updateDoc(projectRef, {
          localeIds: updatedLocaleIds,
        });

        setFilteredLocales(
          filteredLocales.filter((locale) => locale.id !== localeId)
        );
      } catch (e) {
        console.error("Error removing locale: ", e);
      }
    }
  };

  if (!selectedProject) {
    return <div>Project not found</div>;
  }

  const isochrone = selectedProject.isochrone?.map(
    (coord: { latitude: number; longitude: number }) => ({
      lat: coord.latitude,
      lng: coord.longitude,
    })
  );

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();

    if (typeof onTaskSave === "function" && selectedTask) {
      const updatedPipeline = JSON.parse(JSON.stringify(pipeline));
      const { stageIndex, taskIndex } = selectedTask;

      if (typeof stageIndex === "number" && typeof taskIndex === "number") {
        const stages = Object.keys(updatedPipeline);
        const stage = stages[stageIndex];
        const tasks = updatedPipeline[stage] as HierarchicalPipeline;

        if (tasks) {
          const taskKeys = Object.keys(tasks);
          const taskKey = taskKeys[taskIndex];
          const task = tasks[taskKey];

          if (
            typeof task === "object" &&
            task !== null &&
            !Array.isArray(task)
          ) {
            updatedPipeline[stage] = {
              ...tasks,
              [taskKey]: {
                ...task,
                startDate,
                endDate,
                description,
                members: membersIds,
              },
            };
          } else if (typeof task === "boolean") {
            updatedPipeline[stage] = {
              ...tasks,
              [taskKey]: {
                startDate,
                endDate,
                description,
                members: membersIds,
              },
            };
          }

          await onSave(updatedPipeline);
          setTaskDataSaved(true);
        } else {
          console.error("Tasks not found in the selected stage");
        }
      } else {
        console.error("Invalid stageIndex or taskIndex");
      }
    }
  };

  const isLocaleInPolygon = (
    lat: number,
    lng: number,
    coordinates: google.maps.LatLngLiteral[]
  ) => {
    if (!mapRef.current || !coordinates || !google.maps.geometry) {
      console.error("Map, coordinates, or geometry library is missing");
      return false;
    }
    const point = new google.maps.LatLng(lat, lng);
    const polygon = new google.maps.Polygon({ paths: coordinates });
    try {
      return google.maps.geometry.poly.containsLocation(point, polygon);
    } catch (e) {
      console.error("Error checking if point is in polygon:", e);
      return false;
    }
  };

  return (
    <>
      <div style={{ paddingLeft: 250 }} className="mt-[16px] ">
        <h2 className="font-bold text-cprimary mx-4">{selectedProject.name}</h2>
        <hr
          className="mt-[16px] mx-4"
          style={{ border: "1px rgb(255 29 127) solid" }}
        />
        <div className="flex justify-between">
          <div className="flex-1">
            <div
              className="flex "
              style={{
                transform: isTaskClicked ? "translateX(-50%)" : "100%",
                marginLeft: isTaskClicked ? "0px" : "10px",
                marginRight: isTaskClicked ? "0px" : "10px",
                transition: "transform 0.7s ease",
              }}
            >
              <div className="flex-1 ">
                <div style={{ borderRadius: "15px", overflow: "hidden" }}>
                  <GoogleMap
                    mapContainerStyle={{
                      height: "390px",
                      width: "100%",
                    }}
                    center={center}
                    zoom={16}
                    options={{
                      streetViewControl: false,
                      mapTypeControl: false,
                      fullscreenControl: false,
                      zoomControl: false,
                    }}
                    onLoad={(map) => {
                      mapRef.current = map;
                    }}
                  >
                    {polygonDrawn && isochrone && (
                      <Polygon
                        paths={isochrone}
                        options={{
                          fillColor: "#ff1d7f",
                          fillOpacity: 0.15,
                          strokeWeight: 3,
                          strokeColor: "#ff1d7f",
                        }}
                      />
                    )}

                    {polygonDrawn &&
                      filteredLocales.map((locale: any) => (
                        <Marker
                          key={locale.id}
                          position={{
                            lat: locale.location.latitude,
                            lng: locale.location.longitude,
                          }}
                          icon={{
                            url: "/shop.svg",
                            scaledSize: new google.maps.Size(35, 35),
                          }}
                        />
                      ))}
                  </GoogleMap>
                </div>
                <div className="mt-3">
                  {" "}
                  {filteredLocales.length > 0 ? (
                    <div className="flex flex-wrap gap-2 ">
                      {filteredLocales.map((locale: any, idx: any) => (
                        <div
                          key={idx}
                          className=" flex-1 min-w-[310px] max-w-[calc(50%)] flex flex-col relative "
                        >
                          <div className="relative rounded-lg border p-2 bg-white shadow-sm flex-grow flex flex-col">
                            <img
                              src={locale.images[0]}
                              alt="Locale"
                              height={80}
                              className="w-full h-auto rounded min-w-[100%] drop-shadow-sm"
                            />
                            <div className="p-2 flex flex-col flex-grow">
                              <h3
                                className="text-sm mb-1 font-bold"
                                style={{ minHeight: "2.5em" }}
                              >
                                {locale.location.address}
                              </h3>

                              <div className="flex gap-1 flex-wrap opacity-70">
                                <h3 className="flex gap-1 items-center text-xs">
                                  <RxCorners className="text-[10px]" />
                                  <span className="text-[11px]">
                                    {locale.sqMeter} m<sup>2</sup>
                                  </span>
                                </h3>
                                <h3 className="flex gap-1 items-center text-xs">
                                  <BsBuildings className="text-[10px]" />
                                  <span className="text-[11px]">
                                    {locale.floors} Floor(s)
                                  </span>
                                </h3>

                                {locale.haveParking && (
                                  <h3 className="flex gap-1 items-center text-xs">
                                    <FaParking className="text-[10px]" />
                                    <span className="text-[11px]">Parking</span>
                                  </h3>
                                )}

                                {locale.isCorner && (
                                  <h3 className="flex gap-1 items-center text-xs">
                                    <RxCornerTopRight className="text-[10px]" />
                                    <span className="text-[11px]">Corner</span>
                                  </h3>
                                )}

                                {locale.commercial && (
                                  <h3 className="flex gap-1 items-center text-xs">
                                    <AiFillShop className="text-[10px]" />
                                    <span className="text-[11px]">
                                      Commercial
                                    </span>
                                  </h3>
                                )}
                              </div>
                              <div className="flex justify-between items-end mt-2">
                                <h1 className="text-[16px] font-bold">
                                  {USDollar.format(locale.price)}
                                </h1>

                                <Button
                                  onClick={() => handleRemoveLocale(locale.id)}
                                  id="theme-button"
                                  className="position-absolute bottom-4 end-14 d-flex align-items-center justify-content-center shadow"
                                  variant="outline-danger"
                                  style={{
                                    borderRadius: "50%",
                                    width: "40px",
                                    height: "40px",
                                  }}
                                >
                                  <AiOutlineClose />
                                </Button>
                                <Button
                                  className="flex items-center justify-center bg-yellow-400 text-white w-[40px] h-[40px] rounded-xl cursor-pointer mr-[-5px] p-0"
                                  id="theme-button"
                                  onClick={() =>
                                    setCenter({
                                      lat: locale.location.latitude,
                                      lng: locale.location.longitude,
                                    })
                                  }
                                >
                                  <FaMapMarkerAlt className="ml-[11px]" />
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    showNoLocalesMessage && (
                      <div className="p-4 text-center text-lg text-gray-600">
                        No Locale Available in this Isochrone
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={`w-1/2 h-[calc(100%-50px)] overflow-y-auto`}>
                <div>
                  {modalOpen && selectedProject && (
                    <PipelineModal
                      onSave={onSave}
                      pipeline={pipeline}
                      onPipelineChange={onPipelineChange}
                      setIsTaskClicked={setIsTaskClicked}
                      setSelectedTask={setSelectedTask}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                      taskDataSaved={taskDataSaved}
                      setTaskDataSaved={setTaskDataSaved}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {isTaskClicked && (
            <div
              className="absolute left-[59vw] right-[-10%] w-1/2 min-h-[370px] bg-white p-2 flex flex-col"
              style={{
                ...divStyle,
              }}
            >
              <form className="mt-[-20px] d-flex flex-column h-100">
                <input
                  type="text"
                  className="mb-3 mt-3"
                  placeholder="Task Name"
                  value={selectedTask?.name || "Please Save the Task"}
                  style={{
                    fontSize: selectedTask?.name ? "24px" : "20px",
                    fontWeight: "bold",
                    border: "none",
                    width: "100%",
                    outline: "none",
                    color: selectedTask?.name ? "inherit" : "red",
                  }}
                  readOnly
                />

                <Row className="mb-3">
                  <Col>
                    <FloatingLabel controlId="startDate" label="Start Date">
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col>
                    <FloatingLabel controlId="endDate" label="End Date">
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>

                <FloatingLabel
                  controlId="membersDropdown"
                  label=""
                  className="mb-3"
                >
                  <Search
                    list={members}
                    searchingProperty="fullName"
                    setAddingItems={setSelectedMembers}
                    addingItems={selectedMembers}
                    placeholder="Search members"
                    max={10}
                    resetTrigger={resetSearchTrigger}
                  />
                </FloatingLabel>

                <div className="flex-grow-1 " style={{ flexGrow: 1 }}>
                  <EditorProvider>
                    <Editor
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      style={{ minHeight: "340px" }}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnUnderline />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <BtnStyles />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                  <input type="hidden" value={description} name="description" />
                </div>

                <div className="d-flex justify-content-end ">
                  <Button
                    id="theme-button"
                    variant="outline-primary"
                    size="sm"
                    onClick={handleSave}
                    disabled={!selectedTask?.name}
                    className="mb-2 mt-2"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditPage;
