import { useState, useEffect } from "react";
import {
  Button,
  Modal,
  Alert,
  Badge,
  FloatingLabel,
  Form,
} from "react-bootstrap";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import { FaMapMarkerAlt } from "react-icons/fa";
import { db } from "@Config/firebase";
import { Link } from "react-router-dom";
import { updateDoc, doc, arrayUnion } from "firebase/firestore";

const DataMarket = () => {
  const { user } = useAuth();
  const { retails, organizations, categories, isochrones } = useData(); // Add isochrones
  const [selectedItem, setSelectedItem] = useState<any>(null); // Unified state for selected item
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("all"); // Dropdown state

  const defaultOrganizationId = user?.data?.default_organization;
  const defaultOrganization = organizations.find(
    (org) => org.id === defaultOrganizationId
  );
  const purchasedRetails = defaultOrganization?.retails || [];
  const purchasedIsochrones = defaultOrganization?.isochrones || [];
  const member = defaultOrganization?.members?.find(
    (mem: any) => mem.uid === user?.uid
  );

  const handlePurchase = (item: any) => {
    const isRetail = item.type === "retail"; // Determine if it's a retail or isochrone
    if (isRetail && !purchasedRetails.includes(item.id)) {
      setSelectedItem(item);
      setShowConfirmation(true);
    } else if (!isRetail && !purchasedIsochrones.includes(item.id)) {
      setSelectedItem(item);
      setShowConfirmation(true);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (defaultOrganizationId && organizations) {
        const organization = organizations.find(
          (org) => org.id === defaultOrganizationId
        );
        if (organization) {
          const updatedCredits = organization.credits - selectedItem.price;
          if (updatedCredits < 0) {
            setInsufficientCredits(true);
            setShowConfirmation(false);
            return;
          }
          const transaction = {
            amount: selectedItem.price,
            description: `Purchased ${selectedItem.name}`,
            timestamp: new Date(),
            type: "OUTGOING",
            user: user?.uid,
          };

          const updateFields =
            selectedItem.type === "retail"
              ? { retails: arrayUnion(selectedItem.id) }
              : { isochrones: arrayUnion(selectedItem.id) };

          await updateDoc(doc(db, `organizations/${organization.id}`), {
            credits: updatedCredits,
            transactions: arrayUnion(transaction),
            ...updateFields,
          });

          setShowConfirmation(false);
        } else {
          console.error("Default organization not found.");
        }
      } else {
        console.error(
          "Default organization ID or selected item not available."
        );
      }
    } catch (error) {
      console.error("Error updating organization or credits:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (insufficientCredits) {
      setTimeout(() => {
        setInsufficientCredits(false);
      }, 3000);
    }
  }, [insufficientCredits]);

  const getCategoryBadge = (categoryId: string) => {
    const category = categories.find((cat) => cat.id === categoryId);
    return category ? (
      <Badge
        bg={category.color}
        style={{
          color: category.color === "light" ? "black" : "white",
          width: "min-content",
          marginLeft: 8,
        }}
      >
        {category.name}
      </Badge>
    ) : (
      <Badge bg="secondary" style={{ marginLeft: 8 }}>
        No Category Selected
      </Badge>
    );
  };

  // Filter items based on the dropdown selection
  const filteredItems = [
    ...retails.map((item) => ({ ...item, type: "retail" })),
    ...isochrones.map((item) => ({ ...item, type: "isochrone" })),
  ].filter((item) => {
    if (selectedFilter === "all") return true;
    return item.type === selectedFilter;
  });

  if (member?.role === "owner") {
    return (
      <>
        {insufficientCredits && (
          <Alert
            variant="danger"
            onClose={() => setInsufficientCredits(false)}
            dismissible
            className="mt-4 fixed top-0 left-1/2 transform -translate-x-1/2 z-50 w-1/3 text-black text-center p-2 border-b border-gray-200 shadow-lg"
          >
            Insufficient credits,
            <Link
              to={`/manager/organizations/${defaultOrganizationId}`}
              className="btn-xs py-1 px-2 rounded text-black"
            >
              Purchase Credits
            </Link>
          </Alert>
        )}

        <div className="pl-64 mt-4 mx-4">
          <div className="flex justify-between items-center">
            <h2 className="font-bold text-cprimary">The Data Market</h2>
            <div style={{ width: "200px", float: "right" }}>
              {" "}
              {/* Aligns the dropdown to the right */}
              <FloatingLabel controlId="floatingSelect" label="Select Category">
                <Form.Select
                  aria-label="Filter items"
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                >
                  <option value="all">All</option>
                  <option value="retail">Retails</option>
                  <option value="isochrone">Polygons</option>
                </Form.Select>
              </FloatingLabel>
            </div>
          </div>
          <hr style={{ border: "1px rgb(255 29 127) solid" }} />

          {/* Dropdown to filter cards */}

          {/* Render Filtered Items */}
          <div className="grid grid-cols-3 gap-2 mt-4">
            {filteredItems.length > 0 ? (
              filteredItems.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`rounded-lg p-4 border h-full  ${
                    item.type === "isochrone" ? "row-span-2" : ""
                  }`}
                  style={{
                    height: item.type === "isochrone" ? "500px" : "250px", // Adjust height dynamically
                  }}
                >
                  <div className="flex flex-col h-full">
                    <div className="flex items-center mb-2">
                      {item.type === "retail" && item.pin && (
                        <img
                          src={item.pin}
                          alt={item.name}
                          className="w-8 h-8 mr-2 mb-2"
                        />
                      )}
                      {item.type === "isochrone" && item.image && (
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-full object-cover mb-3 rounded-lg"
                        />
                      )}
                    </div>
                    <div className="flex-grow">
                      <h3 className="text-xl font-semibold">{item.name}</h3>

                      {item.type === "isochrone" && (
                        <div className="flex justify-between items-center mt-2">
                          {/* Isochrone Description with scrolling */}
                          <span
                            className="text-sm text-gray-600 overflow-auto max-h-20 w-80"
                            style={{ maxHeight: "5rem", overflowY: "auto" }}
                          >
                            {item.description}
                          </span>

                          {/* Price - Right aligned */}
                          <span className="font-semibold text-right ">
                            {item.price} Credits
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between items-center mt-2">
                      {item.type === "retail" && (
                        <>
                          <div className="flex items-center">
                            <span>
                              <FaMapMarkerAlt className="inline mr-2" />
                              {item.locations}
                            </span>
                            <span className="mr-1">
                              {getCategoryBadge(item.category)}
                            </span>
                          </div>
                          <span className="font-semibold">
                            {item.price} Credits
                          </span>
                        </>
                      )}
                    </div>
                    <div className="mt-3 text-center">
                      <Button
                        className="w-full"
                        id="theme-button"
                        onClick={() => handlePurchase(item)}
                        disabled={
                          item.type === "retail"
                            ? purchasedRetails.includes(item.id)
                            : purchasedIsochrones.includes(item.id)
                        }
                      >
                        {item.type === "retail"
                          ? purchasedRetails.includes(item.id)
                            ? "Purchased"
                            : "Purchase"
                          : purchasedIsochrones.includes(item.id)
                          ? "Purchased"
                          : "Purchase"}
                      </Button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>No items available.</div> // Fallback message or UI
            )}
          </div>

          {/* Confirmation Modal */}
          <Modal
            show={showConfirmation}
            onHide={() => setShowConfirmation(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm Purchase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to purchase {selectedItem?.name} for{" "}
              {selectedItem?.price} credits?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowConfirmation(false)}
                className="btn-xs py-1 px-2 rounded text-black bg-transparent border-0"
              >
                Close
              </Button>
              <Button id="theme-button" onClick={onSubmit} disabled={loading}>
                {loading ? "Processing..." : "Confirm"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  } else {
    return (
      <div className="pl-64">
        <h2 className="font-bold">
          Sorry, this page is restricted to owners only.
        </h2>
      </div>
    );
  }
};

export default DataMarket;
