import { GoAlert } from "react-icons/go";
import { FaSlidersH } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { FaWalking } from "react-icons/fa";
import { MdDirectionsBike } from "react-icons/md";

import { AiFillEdit, AiFillCar } from "react-icons/ai";

import { CiLocationOn } from "react-icons/ci";
import { Link } from "react-router-dom";
import Project from "./Project";
import Places from "./Places";
import Pdf from "./Pdf";
import {
  GoogleMap,
  Marker,
  Polygon,
  HeatmapLayerF,
} from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Form,
  FormControl,
  Modal,
  Offcanvas,
  Accordion,
} from "react-bootstrap";
import axios from "axios";
import Demographics from "./Demographics";
import { useData } from "@Contexts/DataContext";
import { useAuth } from "@Contexts/AuthContext";
import CustomPolygon from "./CustomPolygon";
import FileDropTextarea from "./FileDropTextarea";
import LocaleButton from "./LocaleButton";
import RoadStyleButton from "./RoadStyleButton";

const time = { "10 Minutes": 300, "15 Minutes": 600, "20 Minutes": 900 };

const travelModesORS = {
  DRIVING: "driving-car",
  WALKING: "foot-walking",
  BICYCLING: "cycling-regular",
};

const defaultCenter = {
  lat: 18.8975617,
  lng: -96.9385916,
};

const mapStyle = [
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [{ color: "#f285ae" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [{ color: "#b03a90" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [{ color: "#d15fa0" }],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [{ color: "#e7acb6" }],
  },
];

const ExploreElem = () => {
  const data = useData();

  const [travelMode, setTravelMode] = useState<
    "DRIVING" | "WALKING" | "BICYCLING"
  >("DRIVING");
  let [locations, setLocations] = useState<any>([]);
  let [center, setCenter] = useState<any>();
  const [timeRange, setTimeRange] = useState<
    "10 Minutes" | "15 Minutes" | "20 Minutes"
  >("10 Minutes");

  const [show, setShow] = useState(true);
  const [routeLoading, setRouteLoading] = useState(false);
  const [heatMapLoading, setHeatMapLoading] = useState(true);
  const refPoly = useRef<Polygon | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [refHeatMap, setRefHeatMap] =
    useState<null | google.maps.visualization.HeatmapLayer>(null);
  const [purchaseIsochrone, setPurchaseIsochrone] = useState<Isochrone | null>(
    null
  );

  const refreshVal = useState(0);
  const [isochrone, setIsochrone] = useState<any>([]);

  const [isochroneError, setIsochroneError] = useState<any>("");
  const [populationData, setPopulationData] = useState<PopulationData[]>([]);
  const [populationToggle, setPopulationToggle] = useState(false);
  const [places, setPlaces] = useState<
    {
      name: string;
      type: string;
      length: number;
      results: google.maps.places.PlaceResult[];
    }[]
  >([]);
  const [delayedToggleState, setDelayedToggleState] = useState(false);
  const [drawToggle, setDrawToggle] = useState(false);
  const [filteredRetailers, setFilteredRetailers] = useState<string[]>(
    [] as string[]
  );
  const [placesToggle, setPlacesToggle] = useState(false);
  const handlePlacesToggle = () => {
    if (!isochrone || heatMapLoading || routeLoading) return;
    setPlacesToggle(!placesToggle);
  };
  const [nse, setNSE] = useState<any>({});
  const [demographics, setDemographics] = useState<any>({});

  const { user } = useAuth();

  const [organization, setOrganization] = useState(
    data.organizations
      .filter((org) => org.id === user?.data?.default_organization)
      .map((org) => ({
        ...org,
        members: [
          ...org.members.filter((mem: any) => mem.role !== "owner"),
          ...org.members
            .filter((mem: any) => mem.role === "owner")
            .map((mem: any) => ({
              ...mem,
              credits: data.organizations.filter(
                (org) => org.id === user?.data?.default_organization
              )?.[0]?.credits,
            })),
        ],
      }))?.[0]
  );

  const [disabledDueToOrganization, setDisabledDueToOrganization] = useState(
    !organization ||
      !Boolean(
        organization.members.filter((mem: any) => mem.uid === user.uid)[0]
          .credits
      )
  );
  const [toggleMapStyle, setToggleMapStyle] = useState(false);
  const refresh = () => refreshVal[1](Math.random());
  const showLocations = (isochrone: any) => {
    data.locations.map((location: any) => {
      var cords = new window.google.maps.LatLng({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      });

      if (
        new CustomPolygon(
          isochrone.map((cords: any) => [cords[0], cords[1]])
        ).contains([location.longitude, location.latitude])
      ) {
        locations.push({
          cords,
          ...location,
        });
        refresh();
      }
    });
  };

  const drawIsochrone = async () => {
    setIsochroneError("");
    if (!center) return;
    setRouteLoading(true);
    const response = await axios.post(
      `https://api.openrouteservice.org/v2/isochrones/${travelModesORS[travelMode]}`,
      { locations: [[center.lng, center.lat]], range: [time[timeRange]] },
      {
        headers: {
          Accept:
            "application/json, application/geo+json, application/gpx+xml, img/png; charset=utf-8",
          Authorization: import.meta.env.VITE_ORS_API_KEY,
          "Content-Type": "application/json; charset=utf-8",
        },
      }
    );
    if (response.status === 200) {
      setIsochrone(response.data.features[0].geometry.coordinates[0]);
    }

    setRouteLoading(false);
  };

  useEffect(() => {
    if (isochrone.length) {
      showLocations(isochrone);
    } else {
      setLocations([]);
    }
  }, [isochrone]);

  useEffect(() => {
    setHeatMapLoading(true);

    // Check if purchaseIsochrone is null
    if (purchaseIsochrone && populationToggle) {
      setPopulationData([]);
      refHeatMap?.setMap(null);

      const getLat = (item: any) => item.location.lat;
      const getLng = (item: any) => item.location.lng;

      const transformedData: PopulationData[] =
        purchaseIsochrone.populationOverlay.map((item) => ({
          location: new google.maps.LatLng(getLat(item), getLng(item)), // Call the functions to get lat/lng values
          weight: item.weight,
        }));

      // Set the transformed data
      setPopulationData(transformedData);
      setHeatMapLoading(false); // No need to load as we are using existing data
    } else if (isochrone.length && populationToggle) {
      // If purchaseIsochrone is null, proceed with the API call
      setPopulationData([]);
      refHeatMap?.setMap(null);
      (async () => {
        let polygonLats = isochrone.map((cords: any) => cords[1]);
        let polygonLngs = isochrone.map((cords: any) => cords[0]);

        const data = {
          maxLat: Math.max(...polygonLats),
          minLat: Math.min(...polygonLats),
          maxLng: Math.max(...polygonLngs),
          minLng: Math.min(...polygonLngs),
        };

        polygonLats = null;
        polygonLngs = null;

        let response = await axios.post(
          `${import.meta.env.VITE_API_URL}/overlays/population`,
          {
            ...data,
          }
        );

        if (response.status === 200) {
          setPopulationData([]);
          setPopulationData(
            response.data
              .filter((population: any) =>
                window.google.maps.geometry.poly.containsLocation(
                  new window.google.maps.LatLng({
                    lat: population.latitude,
                    lng: population.longitude,
                  }),
                  // @ts-expect-error
                  (refPoly.current as Polygon).state.polygon
                )
              )
              .map((population: any) => ({
                location: new window.google.maps.LatLng({
                  lat: population.latitude,
                  lng: population.longitude,
                }),
                weight: population["population_2020"],
              }))
          );
        }
        setHeatMapLoading(false);
      })();
    } else {
      // If no isochrone or populationToggle is false
      setPopulationData([]);
      refHeatMap?.setMap(null);
      setHeatMapLoading(false);
    }
  }, [isochrone, populationToggle, purchaseIsochrone]);

  useEffect(() => {
    drawIsochrone().catch(() =>
      setIsochroneError(
        "An error occurred while drawing the isochrone. Try choosing a different location."
      )
    );
  }, [travelMode, timeRange, center]);

  // useEffect(() => {
  //   const storedFilters = window.localStorage.getItem("retail_filter");

  //   if (storedFilters === null)
  //     setFilteredRetailers(
  //       data.retails
  //         .filter((retail) => organization.retails.includes(retail.id))
  //         .map((retail) => retail.name)
  //     );
  //   if (storedFilters !== null)
  //     setFilteredRetailers(JSON.parse(storedFilters || "[]"));
  // }, []);

  useEffect(() => {
    if (drawToggle && isochrone.length >= 4) {
      showLocations(isochrone);
    }
  }, [drawToggle, isochrone]);

  useEffect(() => {
    if (drawToggle) {
      setIsochrone([]);
    } else if (!drawToggle && isochrone.length) {
      setIsochrone([]);
    } else if (!drawToggle && !isochrone.length) {
      drawIsochrone();
    }
  }, [drawToggle]);

  useEffect(() => {
    setOrganization(
      data.organizations
        .filter((org) => org.id === user?.data?.default_organization)
        .map((org) => ({
          ...org,
          members: [
            ...org.members.filter((mem: any) => mem.role !== "owner"),
            ...org.members
              .filter((mem: any) => mem.role === "owner")
              .map((mem: any) => ({
                ...mem,
                credits: data.organizations.filter(
                  (org) => org.id === user?.data?.default_organization
                )?.[0]?.credits,
              })),
          ],
        }))?.[0]
    );
  }, [data.organizations]);

  useEffect(() => {
    setDisabledDueToOrganization(
      !organization ||
        !Boolean(
          organization.members.filter((mem: any) => mem.uid === user.uid)[0]
            .credits
        )
    );
  }, [organization]);

  console.log(populationData);

  return (
    <>
      <div
        style={{
          position: "absolute",
          height: "100vh",
          width: `${window.innerWidth - 250}px`,
          top: 0,
          right: 0,
          cursor: drawToggle ? "crosshair" : "default",
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <LocaleButton
            delayedToggleState={delayedToggleState}
            isochrone={isochrone}
            mapRef={mapRef}
          />
          <RoadStyleButton
            toggleMapStyle={toggleMapStyle}
            setToggleMapStyle={setToggleMapStyle}
            setDelayedToggleState={setDelayedToggleState}
            delayedToggleState={delayedToggleState}
          />
          <Project
            isochrone={isochrone}
            delayedToggleState={delayedToggleState}
          />
        </div>

        <div
          style={{
            boxShadow: "inset 0px 50px 100px -10px black",
          }}
          className="absolute top-0 left-0 h-screen w-[200%] translate-x-[-100px] z-40 pointer-events-none"
        ></div>
        <div className="absolute top-2 left-2 right-2 z-50">
          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              <Button
                className="py-2"
                id="theme-button"
                onClick={() => setShow(true)}
              >
                <AiFillEdit />
              </Button>

              <Button
                variant={"light"}
                className="border"
                style={{
                  pointerEvents: "none",
                  paddingLeft: 12,
                  position: "relative",
                }}
              >
                <CiLocationOn
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: 8,
                  }}
                />
                <div style={{ marginLeft: 16 }}>
                  {
                    locations.filter((location: any) =>
                      filteredRetailers.includes(location.retailer)
                    ).length
                  }
                </div>
              </Button>

              <div
                style={{
                  borderRadius: 6,
                  position: "relative",
                  pointerEvents: drawToggle ? "none" : "auto",
                  opacity: drawToggle ? 0.5 : 1,
                }}
                className="border"
              >
                <Button
                  id={travelMode === "WALKING" ? "theme-button" : "light"}
                  variant={travelMode === "WALKING" ? "theme-button" : "light"}
                  disabled={!center || routeLoading}
                  onClick={() => {
                    setLocations([]);
                    setTravelMode("WALKING");
                  }}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  className="h-full"
                >
                  <FaWalking style={{ marginTop: -4 }} />
                </Button>
                <Button
                  id={travelMode === "BICYCLING" ? "theme-button" : "light"}
                  variant={
                    travelMode === "BICYCLING" ? "theme-button" : "light"
                  }
                  disabled={!center || routeLoading}
                  onClick={() => {
                    setLocations([]);
                    setTravelMode("BICYCLING");
                  }}
                  style={{ borderRadius: 0 }}
                  className="h-full"
                >
                  <MdDirectionsBike style={{ marginTop: -4 }} />
                </Button>
                <Button
                  id={travelMode === "DRIVING" ? "theme-button" : "light"}
                  variant={travelMode === "DRIVING" ? "theme-button" : "light"}
                  disabled={!center || routeLoading}
                  onClick={() => {
                    setLocations([]);
                    setTravelMode("DRIVING");
                  }}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  className="h-full"
                >
                  <AiFillCar style={{ marginTop: -4 }} />
                </Button>
              </div>

              <div
                style={{
                  borderRadius: 6,
                  position: "relative",
                  pointerEvents: drawToggle ? "none" : "auto",
                  opacity: drawToggle ? 0.5 : 1,
                }}
                className="border"
              >
                <Button
                  id={timeRange === "10 Minutes" ? "theme-button" : "light"}
                  variant={
                    timeRange === "10 Minutes" ? "theme-button" : "light"
                  }
                  disabled={!center || routeLoading}
                  onClick={() => {
                    setLocations([]);
                    setTimeRange("10 Minutes");
                  }}
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  10m
                </Button>
                <Button
                  id={timeRange === "15 Minutes" ? "theme-button" : "light"}
                  variant={
                    timeRange === "15 Minutes" ? "theme-button" : "light"
                  }
                  disabled={!center || routeLoading}
                  onClick={() => {
                    setLocations([]);
                    setTimeRange("15 Minutes");
                  }}
                  style={{ borderRadius: 0 }}
                >
                  15m
                </Button>
                <Button
                  id={timeRange === "20 Minutes" ? "theme-button" : "light"}
                  variant={
                    timeRange === "20 Minutes" ? "theme-button" : "light"
                  }
                  disabled={!center || routeLoading}
                  onClick={() => {
                    setLocations([]);
                    setTimeRange("20 Minutes");
                  }}
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  20m
                </Button>
              </div>

              {Boolean(populationData.length) && populationToggle && (
                <div className="mt-2 ml-2 text-white">
                  Total Average Population:{" "}
                  {(populationData as any)
                    .reduce((a: any, b: any) => ({
                      ...a,
                      weight: a.weight + b.weight,
                    }))
                    .weight.toFixed(2)}
                </div>
              )}
            </div>
            <div className="flex gap-2">
              <div className="grid grid-cols-2 gap-x-2 place-items-end">
                <div style={{ marginTop: "-13px" }}>
                  <Form.Check
                    type="switch"
                    reverse
                    className="mt-2 text-white"
                    label="Show Places"
                    onClick={handlePlacesToggle}
                    disabled={!isochrone || heatMapLoading || routeLoading}
                  />
                </div>
                <Form.Check
                  type="switch"
                  reverse
                  className="text-white"
                  label="Draw Mode"
                  onClick={() => setDrawToggle(!drawToggle)}
                  disabled={!isochrone || heatMapLoading || routeLoading}
                />
                <div></div>
                <Form.Check
                  type="switch"
                  reverse
                  className="mt-[-8px] text-white"
                  label={
                    <div className="flex">
                      Population Overlay
                      {heatMapLoading ? (
                        <AiOutlineLoading className="animate-spin text-xs mt-2 ml-3 text-white" />
                      ) : (
                        ""
                      )}
                    </div>
                  }
                  onClick={() => setPopulationToggle(!populationToggle)}
                  disabled={!isochrone || heatMapLoading || routeLoading}
                />
              </div>

              <Preferences
                filteredRetailers={filteredRetailers}
                setFilteredRetailers={setFilteredRetailers}
                isochrone={
                  drawToggle ? [...isochrone, isochrone[0]] : isochrone
                }
                populationData={populationData}
                populationToggle={populationToggle}
                placesToggle={placesToggle}
                center={center}
                mapRef={mapRef}
                refPoly={refPoly}
                nse={nse}
                demographics={demographics}
                places={places}
                setIsochrone={setIsochrone}
                setNse={setNSE}
                disabledDueToOrganization={disabledDueToOrganization}
                setDemographics={setDemographics}
                deductCredit={async () =>
                  await data.deductCredit(organization, user.uid)
                }
                setLocation={(location: any) => {
                  setLocations([]);
                  setCenter(location);
                }}
                setPurchaseIsochrone={setPurchaseIsochrone}
              />
            </div>
          </div>

          <div className="flex items-start justify-between mt-2">
            <div className="flex flex-col gap-1">
              {data.categories
                .filter((category) =>
                  locations
                    .filter((location: any) =>
                      filteredRetailers.includes(location.retailer)
                    )
                    .map((location: any) => location.category)
                    .includes(category.id)
                )
                .map((category) => (
                  <Badge
                    bg={category.color}
                    key={category.name}
                    style={{
                      color: category.color === "light" ? "black" : "white",
                      width: "min-content",
                    }}
                  >
                    {
                      locations.filter(
                        (location: any) =>
                          filteredRetailers.includes(location.retailer) &&
                          location.category === category.id
                      ).length
                    }{" "}
                    {category.name}
                  </Badge>
                ))}
            </div>
            {Boolean(isochrone?.length) && (
              <div className="flex flex-col gap-2 items-end">
                <Demographics
                  isochrone={
                    drawToggle ? [...isochrone, isochrone[0]] : isochrone
                  }
                  nse={nse}
                  setNSE={setNSE}
                  demographics={demographics}
                  setDemographics={setDemographics}
                  purchasedIsochrone={purchaseIsochrone}
                />
                <div>
                  {placesToggle && (
                    <Places
                      map={mapRef.current}
                      points={isochrone.map((coords: any) => [
                        coords[0],
                        coords[1],
                      ])}
                      places={places}
                      setPlaces={setPlaces}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {routeLoading && (
          <span className="z-[999] opacity-100 absolute flex gap-4 items-center right-5 bottom-5 bg-csecondary text-cprimary border-3 border-cprimary rounded py-3 px-4 text-xl font-bold">
            <AiOutlineLoading className="animate-spin" /> Finding Coverage
            Area...
          </span>
        )}
        {isochroneError && (
          <span className="z-[999] opacity-100 absolute flex gap-4 items-center right-5 bottom-5 bg-csecondary text-cprimary border-3 border-cprimary rounded py-3 px-4 text-xl font-bold">
            <GoAlert /> {isochroneError}{" "}
          </span>
        )}
        {drawToggle && isochrone?.length < 4 && (
          <Alert
            variant="warning"
            style={{
              zIndex: 999,
              position: "absolute",
              right: 27,
              bottom: 27,
              display: "flex",
              alignItems: "center",
              gap: 16,
              margin: 0,
            }}
            className="text-xl font-bold"
          >
            <GoAlert /> Not enough vertices for data fetching.
          </Alert>
        )}
        {disabledDueToOrganization && (
          <span
            style={{ marginRight: -13, marginBottom: -13 }}
            className="z-[999] opacity-100 absolute flex gap-3 items-center right-5 bottom-5 bg-red-100 text-red-500 border-3 border-red-500 rounded py-3 px-4 font-bold"
          >
            <GoAlert />{" "}
            {!organization &&
              `You need to be in a organization to access this feature.
            (no default organization)`}
            {organization &&
              !Boolean(
                organization.members.filter(
                  (mem: any) => mem.uid === user.uid
                )[0].credits
              ) &&
              `Insufficient credits.`}
          </span>
        )}

        <GoogleMap
          zoom={14}
          center={center || defaultCenter}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            zIndex: 10,
            opacity: routeLoading ? 0.4 : 1,
            pointerEvents: routeLoading ? "none" : "auto",
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            zoomControl: false,
            styles: toggleMapStyle ? mapStyle : null,
          }}
          onLoad={(map) => {
            mapRef.current = map;
            drawIsochrone();
          }}
          onClick={(e) => {
            if (drawToggle) {
              setIsochrone([...isochrone, [e.latLng?.lng(), e.latLng?.lat()]]);
            }
          }}
        >
          {locations
            .filter((location: any) =>
              filteredRetailers.includes(location.retailer)
            )
            .map((location: any, idx: number) => (
              <Marker
                key={idx}
                position={location.cords}
                icon={{
                  size: new google.maps.Size(35, 35),
                  url: location.pin || "/pin.svg",
                }}
              />
            ))}
          {populationToggle && populationData.length && (
            <HeatmapLayerF
              onLoad={(e) => setRefHeatMap(e)}
              data={populationData}
              options={{ opacity: 0.5 }}
            />
          )}

          {isochrone.length && (
            <Polygon
              paths={isochrone.map(
                (cords: any) =>
                  new window.google.maps.LatLng({
                    lat: cords[1],
                    lng: cords[0],
                  })
              )}
              ref={refPoly}
              editable={false}
              draggable={false}
              options={{
                fillColor: "#ff1d7f",
                fillOpacity: 0.15,
                strokeWeight: 3,
                strokeColor: "#ff1d7f",
              }}
            />
          )}

          {center && <Marker position={center} zIndex={51} />}
        </GoogleMap>
      </div>
      <ChangeLocationModal
        show={show}
        handleClose={() => setShow(false)}
        defaultLocation={JSON.parse(JSON.stringify(center || defaultCenter))}
        refresh={refresh}
        disabledDueToOrganization={disabledDueToOrganization}
        setLocation={(location: any) => {
          setLocations([]);
          setCenter(location);
        }}
        deductCredit={async () =>
          await data.deductCredit(organization, user.uid)
        }
        setIsochrone={setIsochrone}
        mapRef={mapRef}
        setPurchaseIsochrone={setPurchaseIsochrone}
      />
    </>
  );
};

const ChangeLocationModal = ({
  handleClose,
  show,
  setLocation,
  defaultLocation,
  refresh,
  disabledDueToOrganization,
  setPurchaseIsochrone,
  deductCredit,
  setIsochrone,
  mapRef,
}: any) => {
  const [center, setCenter] = useState<any>(
    defaultLocation || { lat: 18, lng: -95 }
  );
  const [marker, setMarker] = useState<any>(
    defaultLocation || { lat: 18, lng: -95 }
  );
  const { locations } = useData();
  const [address, setAddress] = useState("");
  const [custom, setCustom] = useState(false);
  const [alert, setAlert] = useState("");

  const [textContent, setTextContent] = useState<string>("");

  const convertAddress = () => {
    if (address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK" && results !== null) {
          const location = results[0].geometry.location;
          setMarker({ lat: location.lat(), lng: location.lng() });
          setCenter({ lat: location.lat(), lng: location.lng() });
        } else {
        }
      });
    }
  };

  const handleCustom = () => {
    try {
      const json = JSON.parse(textContent);

      if (json.type === "FeatureCollection" && json.features.length > 0) {
        const coordinates = json.features[0].geometry.coordinates[0];
        setIsochrone(coordinates);

        mapRef.current.setCenter({
          lng: coordinates[0][0],
          lat: coordinates[0][1],
        });
      } else {
        setAlert("Invalid GeoJSON format.");
      }
    } catch (error) {
      setAlert("Invalid JSON format.");
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" style={{ zIndex: 99999 }}>
      <Modal.Header closeButton>
        <div className="flex justify-between items-center w-full pr-4">
          <Modal.Title>Select Location</Modal.Title>
          <Form.Check // prettier-ignore
            type="switch"
            label="Custom Isochrone"
            checked={custom}
            onChange={() => setCustom(!custom)}
            reverse
          />
        </div>
      </Modal.Header>
      {custom ? (
        <Modal.Body>
          <div className="mb-3">
            <FileDropTextarea alert={alert} setTextContent={setTextContent} />
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className="mb-3">
            <div className="flex items-center gap-2">
              <span>Address: </span>
              <Form
                className="flex items-center gap-2 w-full"
                onSubmit={(e) => {
                  e.preventDefault();
                  convertAddress();
                }}
              >
                <FormControl
                  placeholder="Type Address"
                  className="w-full"
                  onChange={(e) => setAddress(e.target.value)}
                />
                <Button
                  id="theme-button"
                  type="submit"
                  disabled={disabledDueToOrganization}
                >
                  GO
                </Button>
              </Form>
            </div>

            <div className="flex items-center gap-2 mt-2">
              <span>Latitude: </span>
              <FormControl
                value={marker.lat}
                type="number"
                step="0.0000001"
                onChange={(e) =>
                  setMarker({
                    lng: marker.lng,
                    lat: parseFloat((e.target as HTMLInputElement).value),
                  })
                }
                placeholder="Longitude"
              />
              <span>Longitude: </span>
              <FormControl
                value={marker.lng}
                type="number"
                step="0.0000001"
                onChange={(e) =>
                  setMarker({
                    lat: marker.lat,
                    lng: parseFloat((e.target as HTMLInputElement).value),
                  })
                }
                placeholder="Latitude"
              />
            </div>
          </div>
          <GoogleMap
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              zoomControl: false,
            }}
            onClick={(e) => {
              setMarker({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
              setCenter({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
            }}
            mapContainerStyle={{
              width: "100%",
              height: "60vh",
              borderRadius: 14,
            }}
            center={center}
            zoom={13}
          >
            <Marker position={marker} />
          </GoogleMap>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button
          className="w-100"
          id="theme-button"
          disabled={
            disabledDueToOrganization ||
            !marker.lat ||
            !marker.lng ||
            !locations?.length
          }
          onClick={async () => {
            await deductCredit();
            setPurchaseIsochrone(null);
            if (!custom) {
              setLocation(marker);
              refresh();
            } else {
              handleCustom();
            }
            handleClose();
          }}
        >
          {!locations?.length ? "Please wait..." : "Explore"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Preferences = ({
  filteredRetailers,
  setFilteredRetailers,
  mapRef,
  isochrone,
  populationData,
  populationToggle,
  placesToggle,
  center,
  setNse,
  setDemographics,
  setIsochrone,
  deductCredit,
  refPoly,
  nse,
  demographics,
  disabledDueToOrganization,
  setPurchaseIsochrone,
  places,
}: any) => {
  const data = useData();

  const { isochrones } = useData();
  const { user } = useAuth();
  const [show, setShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [delayedHoverState, setDelayedHoverState] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();
  const [showIsochroneModal, setShowIsochroneModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    retailName: string
  ) => {
    event.stopPropagation();
    const newFiltered = filteredRetailers.includes(retailName)
      ? filteredRetailers.filter((retailer: any) => retailer !== retailName)
      : [...filteredRetailers, retailName];

    setFilteredRetailers(newFiltered);
    window.localStorage.setItem("retail_filter", JSON.stringify(newFiltered));
  };

  const handleIsochroneModalToggle = () => {
    setShowIsochroneModal(!showIsochroneModal);
  };

  useEffect(() => {
    clearTimeout(timeoutId);
    if (delayedHoverState) {
      setTimeoutId(
        setTimeout(() => {
          setIsHovered(true);
        }, 700)
      );
    } else {
      setIsHovered(false);
    }

    return () => clearTimeout(timeoutId);
  }, [delayedHoverState]);

  useEffect(() => {
    const storedFilters = window.localStorage.getItem("retail_filter");
    const userDefaultOrgId = user?.data?.default_organization;
    const organization = data.organizations?.find(
      (org) => org.id === userDefaultOrgId
    );

    if (organization) {
      if (storedFilters === null) {
        setFilteredRetailers(
          data.retails
            .filter((retail) => organization.retails?.includes(retail.id))
            .map((retail) => retail.name)
        );
      } else {
        setFilteredRetailers(JSON.parse(storedFilters || "[]"));
      }
    }
  }, [data, user, setFilteredRetailers]);

  const handleIsochroneSelection = async (isochrone: any) => {
    await deductCredit(); // Call the deductCredit function first
    setPurchaseIsochrone(isochrone); // Pass the whole isochrone object
    setNse(isochrone.nse); // Set NSE based on the isochrone data
    setDemographics(isochrone.demographics); // Set Demographics based on the isochrone data

    // Calculate the center of the polygon
    const bounds = new google.maps.LatLngBounds();

    // Ensure each lat/lng in isochrones is valid and extend bounds
    isochrone.points.forEach((point: { lat: number; lng: number }) => {
      if (!isNaN(point.lat) && !isNaN(point.lng)) {
        bounds.extend(new google.maps.LatLng(point.lat, point.lng));
      }
    });

    if (!bounds.isEmpty()) {
      const center = bounds.getCenter();
      const centerCoordinates = {
        lat: center.lat(),
        lng: center.lng(),
      };

      // Set the map's center using mapRef
      mapRef.current.setCenter({
        lng: centerCoordinates.lng,
        lat: centerCoordinates.lat,
      });
    } else {
      console.error("No valid isochrone points available to calculate center");
    }

    // Set isochrone coordinates
    setIsochrone(
      isochrone.points.map(({ lat, lng }: IsochroneCoordinate) => [lng, lat])
    );
    handleIsochroneModalToggle();
    handleClose();
  };

  return (
    <>
      <button
        id="theme-button"
        className={`bottom-2 left-2 z-[1000] ${
          isHovered ? "w-[150px]" : "w-[40px]"
        } h-10 rounded-md flex justify-center items-center cursor-pointer transition-all duration-500 ease-in-out`}
        onMouseEnter={() => setDelayedHoverState(true)}
        onMouseLeave={() => setDelayedHoverState(false)}
        onClick={handleShow}
      >
        {isHovered && (
          <div className="relative">
            <span
              className={`absolute transform -translate-x-1/2 -translate-y-1/2 ${
                isHovered ? "opacity-100" : "opacity-0"
              } transition-opacity duration-1000 ease-in-out`}
            >
              Preferences
            </span>
          </div>
        )}
        <FaSlidersH
          className={`transition-transform duration-500 ease-in-out ${
            isHovered ? "translate-x-[55px]" : "translate-x-0"
          }`}
        />
      </button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Preferences</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="flex flex-col gap-3">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Datasets</Accordion.Header>
              <Accordion.Body style={{ paddingLeft: "5px" }}>
                {data.retails.map((retail, i) => {
                  const userDefaultOrgId = user?.data?.default_organization;
                  const userOrganization = data.organizations?.find(
                    (org) => org.id === userDefaultOrgId
                  );

                  const isRetailPurchased =
                    userOrganization &&
                    userOrganization.retails &&
                    userOrganization.retails.includes(retail.id);

                  if (isRetailPurchased) {
                    return (
                      <div
                        key={i}
                        className="px-3 py-1 flex items-center gap-2"
                      >
                        <Form.Check
                          label={retail.name}
                          checked={filteredRetailers.includes(retail.name)}
                          onChange={(e) => handleCheckboxClick(e, retail.name)} // <-- Updated here
                        />
                      </div>
                    );
                  }
                  return null;
                })}

                {filteredRetailers.length > 0 &&
                  data.retails.some((retail) =>
                    data.organizations
                      ?.find(
                        (org) => org.id === user?.data?.default_organization
                      )
                      ?.retails?.includes(retail.id)
                  ) && (
                    <div className="px-3 py-1 flex items-center gap-2">
                      <Link to="/manager/market">
                        <button className="btn-xs py-1 px-2 rounded bg-white text-black">
                          + Buy More
                        </button>
                      </Link>
                    </div>
                  )}

                {data.retails.filter((retail) =>
                  data.organizations
                    ?.find((org) => org.id === user?.data?.default_organization)
                    ?.retails?.includes(retail.id)
                ).length === 0 && (
                  <>
                    <div className="text-center">
                      <p>No Dataset</p>
                    </div>
                    <div className="px-3 py-1 flex justify-center">
                      <Link to="/manager/market">
                        <button
                          id="theme-button"
                          className="btn-xs py-1 px-2 rounded"
                        >
                          Purchase Datasets
                        </button>
                      </Link>
                    </div>
                  </>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Modal
            show={showIsochroneModal}
            onHide={handleIsochroneModalToggle}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Available Isochrones</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="overflow-x-auto">
                {isochrones.filter((isochrone) => {
                  const userDefaultOrgId = user?.data?.default_organization;
                  const userOrganization = data.organizations?.find(
                    (org) => org.id === userDefaultOrgId
                  );

                  return userOrganization?.isochrones?.includes(isochrone.id);
                }).length === 0 ? (
                  // Show message when no isochrones are available
                  <div className="text-center p-4">
                    <p className="text-lg font-semibold">
                      There are no isochrone available. Please purchase from{" "}
                      <Link
                        to="/manager/market"
                        style={{ color: "#ff4798" }}
                        className="underline"
                      >
                        Data Market
                      </Link>
                    </p>
                  </div>
                ) : (
                  // Render isochrone cards if available
                  <div className="grid grid-cols-3 gap-2 mt-4">
                    {isochrones
                      .filter((isochrone) => {
                        const userDefaultOrgId =
                          user?.data?.default_organization;
                        const userOrganization = data.organizations?.find(
                          (org) => org.id === userDefaultOrgId
                        );

                        return userOrganization?.isochrones?.includes(
                          isochrone.id
                        );
                      })
                      .map((isochrone, index) => (
                        <div
                          key={index}
                          className="rounded-lg p-4 border h-full flex flex-col"
                        >
                          <div className="flex-grow">
                            <div className="flex items-center mb-2">
                              {isochrone.image && (
                                <img
                                  src={isochrone.image}
                                  alt={isochrone.name}
                                  className="w-full h-[150px] object-cover mb-3 rounded-lg"
                                />
                              )}
                            </div>
                            <div className="flex justify-between items-center mt-2">
                              <div className="flex-grow">
                                <h3 className="text-xl font-semibold">
                                  {isochrone.name}
                                </h3>
                                <div
                                  className="max-h-[100px] overflow-y-auto" // Set max height and scrolling
                                  title={isochrone.description} // Optional: Add title for full description on hover
                                >
                                  <span>{isochrone.description}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 text-center">
                            <Button
                              className="w-full"
                              id="theme-button"
                              disabled={disabledDueToOrganization}
                              onClick={() =>
                                handleIsochroneSelection(isochrone)
                              }
                            >
                              Explore
                            </Button>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Button
            className="w-full"
            id="theme-button"
            onClick={handleIsochroneModalToggle}
          >
            Polygons
          </Button>
          <Pdf
            isochrone={isochrone}
            populationData={populationData}
            populationToggle={populationToggle}
            placesToggle={placesToggle}
            center={center}
            refPoly={refPoly}
            nse={nse}
            demographics={demographics}
            places={places}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ExploreElem;
