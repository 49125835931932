import { useEffect, useState } from "react";
import {
  FormControl,
  FloatingLabel,
  CloseButton,
  Badge,
} from "react-bootstrap";

export default function Search({
  list,
  searchingProperty,
  setAddingItems,
  addingItems,
  placeholder,
  onSearchTextChange,
  max = 9999,
  resetTrigger,
  ...rest
}: SearchProps) {
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    onSearchTextChange?.(searchText);
  }, [searchText]);

  useEffect(() => {
    setSearchText("");
  }, [resetTrigger]);

  return (
    <div>
      {Boolean(addingItems.length) && (
        <div className="flex gap-2 my-2 mb-2" style={{ flexWrap: "wrap" }}>
          {addingItems.length < 4 ? (
            addingItems.map((addingItem, idx) => (
              <Badge
                key={idx}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 4,
                }}
              >
                {addingItem[searchingProperty]}
                <CloseButton
                  variant="white"
                  style={{ fontSize: 10 }}
                  onClick={() => {
                    delete addingItems[idx];
                    setAddingItems(
                      addingItems.filter((addingItem) => addingItem)
                    );
                  }}
                />
              </Badge>
            ))
          ) : (
            <>
              {addingItems.slice(0, 4).map((addingItem, idx) => (
                <Badge
                  key={idx}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 4,
                  }}
                >
                  {addingItem[searchingProperty]}
                  <CloseButton
                    variant="white"
                    style={{ fontSize: 10 }}
                    onClick={() => {
                      delete addingItems[idx];
                      setAddingItems(
                        addingItems.filter((addingItem) => addingItem)
                      );
                    }}
                  />
                </Badge>
              ))}
              {addingItems.length > 4 && (
                <Badge bg="secondary">+{addingItems.length - 4} more</Badge>
              )}
            </>
          )}
        </div>
      )}
      <FloatingLabel label={placeholder}>
        <FormControl
          {...rest}
          disabled={addingItems.length === max}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </FloatingLabel>
      <div className="flex flex-col">
        {searchText &&
          addingItems.length !== max &&
          list
            .filter((item: any) =>
              item[searchingProperty]
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
            .slice(0, 4)
            .map((result: any, idx: number) => (
              <div
                key={idx}
                onClick={() => {
                  setAddingItems([...addingItems, result]);
                }}
                className="text-black bg-gray-100 pl-4 cursor-pointer py-2 hover:border hover:border-yellow-400"
                style={{
                  opacity: addingItems
                    .map((item: any) => item[searchingProperty])
                    .includes(result[searchingProperty])
                    ? 0.4
                    : 1,
                  pointerEvents: addingItems
                    .map((item: any) => item[searchingProperty])
                    .includes(result[searchingProperty])
                    ? "none"
                    : "auto",
                }}
              >
                {result[searchingProperty]}
              </div>
            ))}
      </div>
    </div>
  );
}
