import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "@Pages/index";
import Navigation from "./components/Navigation";
import NotFound from "@Pages/404";
import Login from "@Pages/login";
import { AuthProvider } from "./contexts/AuthContext";
import Dashboard from "@Pages/common/dashboard";
import Search from "@Pages/manager/search";
import AccountManager from "@Pages/account-manager";
import VenueAdd from "@Pages/broker/venue-add";
import Profile from "@Pages/common/profile";
import Explore from "@Pages/manager/explore";
import Models from "@Pages/manager/models";
import SignUp from "@Pages/signup";
import Databases from "@Pages/admin/databases";
import Database from "@Pages/admin/databases/[id]";
import "./tailwind.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { DataProvider } from "./contexts/DataContext";
import { useJsApiLoader } from "@react-google-maps/api";
import Projects from "@Pages/manager/projects";
import EditPage from "@Pages/manager/projects/[id]";
import Categories from "@Pages/admin/databases/categories";
import Organizations from "@Pages/manager/organizations";
import OrganizationPage from "@Pages/manager/organizations/[id]";
import ProtectedRoute from "@Components/ProtectedRoute";
import InvitationPage from "@Pages/invitation/[id]";
import DataMarket from "@Pages/manager/market";
import TopRetail from "@Pages/admin/top_retail";
import Templates from "@Pages/admin/templates";
import Isochrone from "@Pages/admin/isochrones";

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: ["visualization", "places"],
  });

  return (
    <>
      <Router>
        <AuthProvider>
          <DataProvider>
            <Navigation />
            <Routes>
              <Route path="/" element={<Home />} />

              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/manager/organizations"
                element={
                  <ProtectedRoute role="manager">
                    <Organizations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/organizations/:id"
                element={
                  <ProtectedRoute role="manager">
                    <OrganizationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/search"
                element={
                  <ProtectedRoute role="manager">
                    <Search isLoaded={isLoaded} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/market"
                element={
                  <ProtectedRoute role="manager">
                    <DataMarket />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/explore"
                element={
                  <ProtectedRoute role="manager">
                    <Explore isLoaded={isLoaded} />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/projects"
                element={
                  <ProtectedRoute role="manager">
                    <Projects />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/projects/:id"
                element={
                  <ProtectedRoute role="manager">
                    <EditPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manager/models"
                element={
                  <ProtectedRoute role="manager">
                    <Models />
                  </ProtectedRoute>
                }
              />

              <Route path="/account-manager" element={<AccountManager />} />

              <Route
                path="/broker/venue-add"
                element={
                  <ProtectedRoute role="broker">
                    <VenueAdd isLoaded={isLoaded} />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/admin/databases"
                element={
                  <ProtectedRoute role="admin">
                    <Databases />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/databases/categories"
                element={
                  <ProtectedRoute role="admin">
                    <Categories />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/databases/:id"
                element={
                  <ProtectedRoute role="admin">
                    <Database />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/top_retail"
                element={
                  <ProtectedRoute role="admin">
                    <TopRetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/templates"
                element={
                  <ProtectedRoute role="admin">
                    <Templates />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/polygons"
                element={
                  <ProtectedRoute role="admin">
                    <Isochrone />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <ProtectedRoute>
                    <Profile />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/invitation/:id"
                element={
                  <ProtectedRoute inverse>
                    <InvitationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/login"
                element={
                  <ProtectedRoute inverse>
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/signup"
                element={
                  <ProtectedRoute inverse>
                    <SignUp />
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </DataProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
